import { Box, Text } from '@chakra-ui/react'
import { Heading } from '../Heading'

const SubscriptionPage = () => {
  return (
    <Box height={'100vh'}>
      <Box
        height={'300px'}
        backgroundImage={
          'https://s3.us-west-2.amazonaws.com/prod.qp.me/images/welcome.jpg'
        }
        backgroundSize={'contain'}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center center'}
        backgroundColor={'#fff'}
      ></Box>
      <Box p={4} backgroundColor={'#fff'}>
        <Heading type="h1" mb={1} textAlign="center">
          How do I get access to this content?
        </Heading>

        <Text
          textAlign="center"
          fontSize="medium"
          lineHeight="24px"
          maxWidth={'400px'}
          margin={'0 auto'}
        >
          Visit our website to subscribe to your favourite content creator. Then
          you can view their content in app.
        </Text>
        <Text
          textAlign="center"
          fontSize="medium"
          lineHeight="24px"
          maxWidth={'400px'}
          margin={'0 auto'}
        >
          If you have any issues please contact us at{' '}
          <a href="mailto:support@qp.me">support@qp.me</a>
        </Text>
      </Box>
    </Box>
  )
}

export { SubscriptionPage }
