import { Box, Button } from '@chakra-ui/react'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { CenteredSpinner } from '../CenteredSpinner'

interface PayNowProps {
  stripeClientSecret: string
  paymentMethodId: string
  postId: string
}

export default function PayNow({
  stripeClientSecret,
  paymentMethodId,
  postId,
}: PayNowProps) {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { channelOwnerName, channelIdentifier } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
  }>()

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (!stripe || !elements) {
        return
      }

      setIsLoading(true)

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        stripeClientSecret,
        {
          payment_method: paymentMethodId,
          return_url: `${window.location.origin}/${channelOwnerName}/${channelIdentifier}/payment-success?postId=${postId}`,
        }
      )

      setIsLoading(false)

      if (paymentIntent.status === 'succeeded') {
        setIsLoading(false)
        navigate(
          `/${channelOwnerName}/${channelIdentifier}/payment-success?postId=${postId}`
        )
        return
      }

      setErrorMessage(error.message || 'Something went wrong.')
      setIsLoading(false)
    },
    [
      channelIdentifier,
      channelOwnerName,
      elements,
      navigate,
      paymentMethodId,
      stripe,
      stripeClientSecret,
      postId,
    ]
  )

  if (errorMessage) {
    return (
      <Box py={6} color="red.600">
        {errorMessage}
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button
        mt={4}
        disabled={isLoading || !stripe || !elements}
        id="submit"
        type="submit"
        variant="primary"
      >
        {isLoading ? <CenteredSpinner /> : 'Pay Now'}
      </Button>
    </form>
  )
}
