//import { isIOS, isAndroid } from 'react-device-detect'
import { useQuery } from '../../utils/useQuery'
import { getConfig } from '../../config'
import { useParams, useSearchParams } from 'react-router-dom'
import PostPurchase from '../PostPurchase'
import PostPurchaseTemplate from '../PostPurchase/PostPurchaseTemplate'
import PublicLayout from '../layout/PublicLayout'
import PublicPostPage from './PublicPostPage'

const PostPage = () => {
  const { channelOwnerName, channelIdentifier, postId } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
    postId: string
  }>()

  const query = useQuery()
  const branchChannel = query.get('channel')
    ? 'channel=' + query.get('channel')
    : 'channel=' +
      (channelOwnerName ? channelOwnerName : '') +
      (channelIdentifier ? '/' + channelIdentifier : '') +
      (postId ? '/' + postId : '')
  const branchCode = query.get('code') ? '&code=' + query.get('code') : ''
  const branchSource = query.get('s') ? '&s=' + query.get('s') : ''
  const branchReferrer = query.get('r') ? '&r=' + query.get('r') : ''

  const branchLink =
    getConfig().branch.branchLink +
    `?${branchChannel}${branchCode}${branchSource}${branchReferrer}`

  const playStoreUp = true
  const androidURL = playStoreUp ? branchLink : '/android'

  // async function copyToClipboard() {
  //   if (isIOS || isAndroid) {
  //     //await navigator.clipboard.writeText(branchLink)
  //     window.location.replace(branchLink)
  //   }
  // }

  // copyToClipboard()

  return (
    <PublicLayout branchLink={branchLink} androidURL={androidURL}>
      <PublicPostPage
        channelIdentifier={channelIdentifier}
        channelOwnerName={channelOwnerName}
        postId={postId}
      />
    </PublicLayout>
  )
}

function PostPageWrapper() {
  const [searchParams] = useSearchParams()
  const signedIn = searchParams.get('token')

  if (signedIn) {
    return (
      <PostPurchaseTemplate>
        <PostPurchase />
      </PostPurchaseTemplate>
    )
  }

  return <PostPage />
}

export default PostPageWrapper
