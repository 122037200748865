import Card from '../../Card'
import { PostTypeProps } from '../types'

// interface ImageProps {
//   post: components['schemas']['PublicWebPost']
//   to: To
// }

export default function Image({ post, ...rest }: PostTypeProps) {
  const {
    title: { text },
    downloadUrl,
  } = post

  return (
    <Card
      className="image"
      bgCoverElement={<img alt={text} src={downloadUrl} />}
      {...rest}
    />
  )
}
