import { useQuery } from 'react-query'
import { paths } from '../api-types/channels-api-types'
import { webGatewayApi } from '../services/base'

type GetPublicPostResponseData =
  paths['/channels/pub/profile/{profile}/{identifier}/posts']['get']['responses']['200']['content']['application/json']

interface UseGetPublicPostParams {
  profileId: string
  channelId: string
  postId: string
}

export default function useGetPublicPost({
  profileId,
  channelId,
  postId,
}: UseGetPublicPostParams) {
  return useQuery(['public-post', profileId, channelId, postId], async () => {
    const { data, errors } = await webGatewayApi.get<GetPublicPostResponseData>(
      `/channels/pub/profile/${profileId}/${channelId}/posts?postId=${postId}`
    )

    if (errors.length > 0) {
      throw new Error(errors[0].message)
    }

    const [currentPost] = data

    return {
      currentPost,
      related: [...data].sort((a, b) => {
        return (
          new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime()
        )
      }),
    }
  })
}
