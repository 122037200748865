const Heading = {
  baseStyle: {
    letterSpacing: '0.013em',
    color: 'var(--chakra-colors-primary-950)',
  },
  variants: {
    h1: {
      fontSize: '28px',
      marginBottom: 4,
      lineHeight: '34px',
    },
    h2: {
      fontSize: '17px',
      marginBottom: 4,
      lineHeight: '22px',
    },
  },
}

export default Heading
