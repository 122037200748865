import { ReactNode, useState } from 'react'
import classes from './ShareBtn.module.css'
import { ReactComponent as ShareIcon } from '../../theme/icons/share.svg'
import { createPortal } from 'react-dom'
import ShareSocial from '../ShareSocial'

interface ShareBtnProps {
  children?: ReactNode
}

export default function ShareBtn({ children }: ShareBtnProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}
        type="button"
        className={classes.btn}
      >
        <ShareIcon width={16} height={16} title="QP" />
        {children}
      </button>
      {isOpen
        ? createPortal(
            <div
              className={classes.overlay}
              onClick={(e) => {
                e.stopPropagation()
                setIsOpen(!isOpen)
              }}
            >
              <div
                className={classes.modal}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <button
                  className={classes.close}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsOpen(!isOpen)
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    height="18"
                    width="18"
                  >
                    <defs></defs>
                    <title>close</title>
                    <path
                      d="m.75 23.249 22.5-22.5"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M23.25 23.249.75.749"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </button>
                <ShareSocial
                  title="Share"
                  url={window.location.href}
                  socialTypes={[
                    'facebook',
                    'twitter',
                    'linkedin',
                    'whatsapp',
                    'reddit',
                    'email',
                  ]}
                  onSocialButtonClicked={(data) => {
                    if (data === 'email') {
                      window.location.href = `mailto:?subject=Check out this post!&body=Check%20out%20this%20post%20-%20${window.location.href}`
                    }
                  }}
                  style={{
                    root: {
                      background: '#ffffff',
                      border: 0,
                      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                      color: 'white',
                    },
                    copyContainer: {
                      border: '1px solid blue',
                      background: 'rgb(0,0,0,1)',
                    },
                    title: {
                      color: 'aquamarine',
                      fontStyle: 'italic',
                    },
                  }}
                />
              </div>
            </div>,
            document.body
          )
        : null}
    </>
  )
}
