import { components } from '../../../api-types/channels-api-types'
import useGetChannelPosts from '../../../hooks/useGetChannelPosts'
import { ChannelCard, PostCard } from '../../Cards'
import classes from './ChannelRow.module.css'

interface ChannelRowProps {
  channel: components['schemas']['PublicWebChannel']
  channelOwnerName: string
}

export default function ChannelRow({
  channel,
  channelOwnerName,
}: ChannelRowProps) {
  const { identifier, name, channelImageUrl } = channel

  const { data } = useGetChannelPosts({
    profileId: channelOwnerName,
    channelId: identifier,
  })

  if (!data) {
    // TODO: fallback state
    return null
  }

  return (
    <div className={classes.row}>
      <ChannelCard
        label={name}
        to={`/${channelOwnerName}/${identifier}`}
        imageUrl={channelImageUrl}
      />

      {data.map((post) => {
        const { id } = post

        return (
          <PostCard
            key={id}
            post={post}
            to={`/${channelOwnerName}/${identifier}/${id}`}
          />
        )
      })}
    </div>
  )
}
