import { useContext } from 'react'
import {
  useOutletContext,
  useLocation,
  Outlet,
  Navigate,
} from 'react-router-dom'
import { AuthContext } from '../../providers/AuthProvider'
import { CenteredSpinner } from '../CenteredSpinner'

export function PrivateOutlet() {
  const { signedIn, initialized } = useContext(AuthContext)
  const outletContextValue = useOutletContext()
  const location = useLocation()

  return !initialized ? (
    <CenteredSpinner />
  ) : signedIn ? (
    <Outlet context={outletContextValue} />
  ) : (
    <Navigate
      to={'../login'}
      state={{ from: `${location.pathname}${location.search}` }}
      replace
    />
  )
}
