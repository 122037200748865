import { useEffect, useState } from 'react'
import { useAuth, useAuthMethods } from '../../providers/AuthProvider'
import { useQuery } from '../../utils/useQuery'

export function useAccessTokenQueryParamToAuthenticate() {
  const query = useQuery()
  const token = query.get('token')
  const { signedIn } = useAuth()
  const { handleTokenValidate } = useAuthMethods()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!signedIn && token) {
      handleTokenValidate({ accessToken: token }).finally(() => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [signedIn, token, handleTokenValidate])

  return { isLoading }
}
