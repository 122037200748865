import {
  Box,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useAuthMethods } from '../../providers/AuthProvider'
import { Heading } from '../Heading'

const EmbeddedLoginForm = () => {
  const [formData, updateFormData] = useState({ username: '', password: '' })
  const [errors, setErrors] = useState<string[]>([])

  const { handleLogin } = useAuthMethods()

  const handleChange = (e) => {
    setErrors([])
    updateFormData({
      ...formData,

      [e.target.name]: e.target.value.trim(),
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const response = await handleLogin(formData)

    if (response.errors.length) {
      setErrors(response.errors.map(({ message }) => message))
    }
  }

  return (
    <Box
      maxWidth={450}
      margin="0 auto"
      p={4}
      borderTopWidth={1}
      borderTopColor={'boxBorder.default'}
    >
      <form onSubmit={handleSubmit}>
        <FormControl isInvalid={!!errors?.length}>
          <Heading type="h2" mb={3}>
            Login
          </Heading>

          <Box mb={7}>
            <Input
              id="username"
              name="username"
              type="text"
              placeholder="Username"
              required
              mb={3}
              value={formData.username}
              onChange={handleChange}
            />

            <Input
              id="password"
              name="password"
              type="password"
              required
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.map((e) => (
              <FormErrorMessage key={e}>{e}</FormErrorMessage>
            ))}
          </Box>

          <Button
            variant="primary"
            isDisabled={!(formData.username && formData.password)}
            type={'submit'}
          >
            Login
          </Button>
          <Button variant="link">Forgot password?</Button>
        </FormControl>
      </form>
    </Box>
  )
}

export { EmbeddedLoginForm }
