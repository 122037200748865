import { Icon, IconProps } from '@chakra-ui/react'

const OfferFeatureCheckIcon = ({ boxSize = 3 }: IconProps) => (
  <Icon boxSize={boxSize} viewBox="0 0 10 10" fill="none">
    <path
      d="M8.75 1L3.75 6.99973L1.25 4"
      stroke="#0045FF"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export default OfferFeatureCheckIcon
