import { Box } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'

export function SelectPaymentMethods({
  paymentMethods,
  isDisabled,
  value,
  onChange,
}) {
  const onSelectedPaymentMethodChange = (paymentMethod) => {
    onChange(paymentMethod)
  }

  return (
    <Box mb={3}>
      <Select
        size="md"
        isDisabled={isDisabled}
        value={value}
        tagVariant="solid"
        options={paymentMethods}
        onChange={onSelectedPaymentMethodChange}
        isSearchable={false}
      />
    </Box>
  )
}
