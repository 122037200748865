import { useQuery } from 'react-query'
import { paths } from '../api-types/channels-api-types'
import { webGatewayApi } from '../services/base'

type GetUserChannelsByProfileResponseData =
  paths['/channels/pub/profile/{identifier}']['get']['responses']['200']['content']['application/json']

interface UseGetPostParams {
  channelOwnerName: string
}

export default function useGetProfile({ channelOwnerName }: UseGetPostParams) {
  return useQuery(['public-profile', channelOwnerName], async () => {
    const { data, errors } =
      await webGatewayApi.get<GetUserChannelsByProfileResponseData>(
        `/channels/pub/profile/${channelOwnerName}`
      )

    if (errors.length > 0) {
      throw new Error(errors[0].message)
    }

    return data
  })
}
