import { components } from '../../../api-types/channels-api-types'
import LockedPostCard from './LockedPostCard'
import { Image, Video, Audio, Text, Ama } from './PostTypes'
import { PostTypeProps } from './types'
import classes from './PostCard.module.css'
import classNames from 'classnames'

const cx = classNames.bind(classes)

type PostType =
  | components['schemas']['PublicWebPost']['postContentType']
  | 'AMA'

const postTypeMap: Record<PostType, (props: PostTypeProps) => JSX.Element> = {
  Text,
  Image,
  Audio,
  Video,
  Unknown: Text,
  AMA: Ama,
}

// interface PostCardProps {
//   post: components['schemas']['PublicWebPost']
//   to: To
// }

export default function PostCard({ post, detailView, ...rest }: PostTypeProps) {
  const { postContentType, mediaPostType, locked } = post
  const type = mediaPostType === 'AMA' ? 'AMA' : postContentType
  const PostTypeCard = postTypeMap[type]
  const wrapperClass = classes[type]

  if (locked) {
    return (
      <LockedPostCard
        post={post}
        className={cx({
          [wrapperClass]: !detailView,
        })}
        detailView={detailView}
        {...rest}
      />
    )
  }

  return (
    <PostTypeCard
      post={post}
      className={cx({
        [wrapperClass]: !detailView,
      })}
      detailView={detailView}
      {...rest}
    />
  )
}
