const Button = {
  baseStyle: {
    fontSize: '13x',
    lineHeight: '18px',
    letterSpacing: '0.51px',
    height: 8,
    width: '100%',
  },
  variants: {
    primary: {
      backgroundColor: 'accentBlue.500',
      color: 'white',
      fontSize: '17px',
      lineHeight: '22px',
      letterSpacing: '0.37px',
      fontWeight: 500,
      _hover: {
        _disabled: {
          background: 'accentBlue.500',
        },
      },
    },
    secondary: {
      backgroundColor: 'accentPink.400',
      _hover: {
        _disabled: {
          background: 'accentBlue.400',
        },
      },
      color: 'accentPink.600',
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '0.51px',
      fontWeight: 600,
    },
    link: {
      color: 'accentBlue.500',
      fontSize: '13px',
      lineHeight: '18px',
      letterSpacing: '0.51px',
      fontWeight: 600,
    },
  },
  defaultProps: {
    variant: 'primary',
  },
}

export default Button
