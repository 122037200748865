import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    // styles={`
    //   @font-face {
    //     font-family: 'SF Pro Rounded';
    //     src: url('/SF-Pro-Rounded-Regular.ttf')  format('truetype');
    //   }
    //   `}
    styles={``}
  />
)

export default Fonts
