import { webGatewayApi } from '../base'

type LoginRequestParams = {
  accessToken: string
}
export type LoginResponseData = {
  access_token: string
}
export const validateToken = async ({ accessToken }: LoginRequestParams) => {
  const response = await webGatewayApi.post<LoginResponseData>(`/validate`, {
    body: accessToken,
    headers: { 'Content-Type': 'text/plain' },
  })

  return response
}
