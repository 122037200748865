import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { paths } from '../../api-types/pp-api-types'
import { webGatewayApi } from '../../services/base'

type PostPurchaseResponseData =
  paths['/payments/posts/purchase']['post']['responses']['200']['content']['application/json']

export default function usePostPurchase(
  postId: string,
  paymentMethodId: string
) {
  const { mutate: createPostPurchase, ...rest } = useMutation({
    mutationFn: async () => {
      const { data: responseData, errors } =
        await webGatewayApi.post<PostPurchaseResponseData>(
          '/payments/posts/purchase',
          {
            data: { postId, paymentMethodId },
          }
        )

      if (errors.length > 0) {
        throw new Error(errors[0].message)
      }

      return responseData
    },
  })

  useEffect(() => {
    createPostPurchase()
  }, [createPostPurchase])

  return { ...rest, createPostPurchase }
}
