import ShareBtn from '../../ShareBtn'
import classes from './Header.module.css'

interface HeaderProps {
  displayName: string
  username: string
  bio: string
  imageUrl: string
}

export default function Header({
  displayName,
  username,
  bio,
  imageUrl,
}: HeaderProps) {
  return (
    <div className={classes.wrapper}>
      <div className={classes['profile-image']}>
        <img
          className={classes['profile-image__img']}
          src={imageUrl}
          alt={displayName}
        />
      </div>

      <div className={classes['profile-details']}>
        <h3 className={classes.displayname}>{displayName}</h3>
        <span className={classes.username}>{username}</span>

        <div className={classes.bio}>
          <p>{bio}</p>
        </div>

        <div className={classes.actions}>
          <ShareBtn>Share</ShareBtn>
        </div>
      </div>
    </div>
  )
}
