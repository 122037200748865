import { useState, useEffect } from 'react'
import { webGatewayApi } from '../../services/base'

export const NEW_PAYMENT_METHOD_OPTION = {
  value: 'new',
  label: 'Add a new payment method...',
}

interface GetCustomerPaymentMethods {
  paymentMethodCards: {
    id: string
    cardType: string
    last4: string
    expMonth: string
    expYear: string
  }[]
}

const paymentMethodToPaymentMethodOption = ({
  id,
  cardType,
  last4,
  expMonth,
  expYear,
}) => ({
  label: `${cardType} - xxxx-xxxx-xxxx-${last4} - ${expMonth}/${expYear}`,
  value: id,
})

export const usePaymentMethods = () => {
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [{ paymentMethods, selectedPaymentMethod, selectedIsNew }, setState] =
    useState({
      paymentMethods: [],
      selectedPaymentMethod: undefined,
      selectedIsNew: false,
    })

  const setSelectedPaymentMethod = (selectedPaymentMethod) =>
    setState((previous) => ({
      ...previous,
      selectedPaymentMethod,
      selectedIsNew:
        selectedPaymentMethod.value === NEW_PAYMENT_METHOD_OPTION.value,
    }))

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data, errors } =
        await webGatewayApi.get<GetCustomerPaymentMethods>(
          '/payments/customerpaymentmethods'
        )

      if (errors.length > 0) {
        console.error(...errors)
        setHasError(true)
      }

      const paymentMethodCardsResponseData = data?.paymentMethodCards ?? []

      setState((previous) => ({
        ...previous,
        selectedIsNew: paymentMethodCardsResponseData.length === 0,
        selectedPaymentMethod: paymentMethodCardsResponseData.length
          ? undefined
          : NEW_PAYMENT_METHOD_OPTION,
        paymentMethods: [
          NEW_PAYMENT_METHOD_OPTION,
          ...paymentMethodCardsResponseData.map(
            paymentMethodToPaymentMethodOption
          ),
        ],
      }))

      setLoading(false)
    })()
  }, [])

  return {
    loading,
    paymentMethods,
    selectedPaymentMethod,
    selectedIsNew,
    setSelectedPaymentMethod,
    hasError,
  }
}
