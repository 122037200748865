import { webGatewayApi } from '../base'

type LoginRequestParams = {
  username: string
  password: string
}
export type LoginResponseData = {
  access_token: string
}
export const login = async ({ username, password }: LoginRequestParams) => {
  const response = await webGatewayApi.post<LoginResponseData>(`/login`, {
    data: { username, password },
  })

  return response
}
