import { DownloadIcon } from '@chakra-ui/icons'
import { Box, Stack, Link, Button, Text } from '@chakra-ui/react'
import { Heading } from '../Heading'

const AndroidPage = () => {
  const downloadUrl = 'https://qp.me/android.apk'
  return (
    <Box height={'100vh'}>
      <Box
        height={'400px'}
        backgroundImage={
          'https://s3.us-west-2.amazonaws.com/prod.qp.me/images/welcome.jpg'
        }
        backgroundSize={'contain'}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center center'}
        backgroundColor={'#fff'}
      ></Box>
      <Box p={4} backgroundColor={'#fff'}>
        <Heading type="h1" mb={3} textAlign="center">
          Please download the latest version of QP
        </Heading>

        <Stack direction="row" align="center" justifyContent="center" mt="6">
          <Link href={downloadUrl}>
            <Button leftIcon={<DownloadIcon />} variant="primary">
              Download Now
            </Button>
          </Link>
        </Stack>
        <Text textAlign="center" fontSize="sm" lineHeight="36px">
          Version 1.1.0
        </Text>
      </Box>
    </Box>
  )
}

export { AndroidPage }
