import { useMutation } from 'react-query'
import { paths } from '../../api-types/pp-api-types'
import { webGatewayApi } from '../../services/base'

type PostAmaPurchaseResponseData =
  paths['/payments/ama/questions/purchase']['post']['responses']['200']['content']['application/json']

export default function usePostAmaPurchase() {
  return useMutation({
    mutationFn: async ({
      questionId,
      paymentMethodId,
    }: {
      questionId: string
      paymentMethodId: string
    }) => {
      const { data: responseData, errors } =
        await webGatewayApi.post<PostAmaPurchaseResponseData>(
          '/payments/ama/questions/purchase',
          {
            data: { questionId, paymentMethodId },
          }
        )

      if (errors.length > 0) {
        throw new Error(errors[0].message)
      }

      return responseData
    },
  })
}
