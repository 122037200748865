import { Heading } from '../Heading'
import { Box, Stack, useBreakpointValue } from '@chakra-ui/react'
import { PostItemGrid } from '../PostItemGrid'
import { components as ChannelsApiComponents } from '../../api-types/channels-api-types'
import { useEffect } from 'react'
import { useAuthMethods } from '../../providers/AuthProvider'
import { SubscriptionOptionCard } from '../SubscriptionOptionCard'

export interface ChannelSubscriptionOptionsProps {
  subscriptionOptions?: ChannelsApiComponents['schemas']['SubscriptionOption'][]
  onChange(subscriptionId: string, promoId: string, isFree: boolean): void
  posts?: { imageSrc: string; imageAlt: string }[]
}

const ChannelSubscriptionOptions = ({
  subscriptionOptions,
  posts,
  onChange: handleSubscriptionOptionChange,
}: ChannelSubscriptionOptionsProps) => {
  const { handleLogout } = useAuthMethods()

  useEffect(() => {
    handleLogout()
  }, [handleLogout])

  const stackDirection: 'row' | 'column' = useBreakpointValue({
    base: 'column',
  })

  const responsiveBoxStyles = useBreakpointValue({
    base: {
      // backgroundColor: '#e5e5e533',
      //borderTop: 'solid 1px #EDEEEF',
      //borderBottom: 'solid 1px #EDEEEF',
    },
  })

  return (
    <>
      {subscriptionOptions && (
        <Box pb={10} {...responsiveBoxStyles}>
          <p>Choose your plan</p>

          <Stack spacing={5} direction={stackDirection}>
            {subscriptionOptions
              .filter((option) => {
                return option.tier > 1
              })
              .sort(
                (soA, soB) =>
                  (soA.priceOption?.price ?? 0) - (soB.priceOption?.price ?? 0)
              )
              .sort((soA, soB) => (soA.tier ?? 0) - (soB.tier ?? 0))
              .map((subscriptionOption, index, array) => (
                <SubscriptionOptionCard
                  key={subscriptionOption.id}
                  value={subscriptionOption.id}
                  name={subscriptionOption.name}
                  description={subscriptionOption.description}
                  previousTier={index ? array[index - 1].name : ''}
                  priceOption={subscriptionOption.priceOption}
                  promoOptions={subscriptionOption.promoOptions}
                  subscriptionAddOns={subscriptionOption.addOns}
                  status={subscriptionOption.status !== 'Full'}
                  withMessaging={subscriptionOption.withMessaging}
                  onClick={handleSubscriptionOptionChange}
                />
              ))}
          </Stack>
        </Box>
      )}
      {posts && (
        <Box p={4}>
          <Heading textAlign={'center'} type={'h2'}>
            {'Posts'}
          </Heading>
          <PostItemGrid posts={posts} />
        </Box>
      )}
    </>
  )
}

export { ChannelSubscriptionOptions }
