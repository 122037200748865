import { useParams } from 'react-router-dom'
import PaymentMethod from './PaymentMethod'
import { useAccessTokenQueryParamToAuthenticate } from '../Authentication'
import { Box, Heading } from '@chakra-ui/react'
import { useAuth } from '../../providers/AuthProvider'
import { CenteredSpinner } from '../CenteredSpinner'

export default function PostPurchase() {
  const { signedIn } = useAuth()
  const { postId } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
    postId: string
  }>()
  useAccessTokenQueryParamToAuthenticate()

  if (!signedIn) {
    return <CenteredSpinner />
  }

  return (
    <Box py={6}>
      <Heading
        as="h2"
        fontSize={17}
        lineHeight={1.3}
        fontWeight={500}
        color={'primary.950'}
      >
        Payment Method
      </Heading>
      <Box my={4}>
        <PaymentMethod postId={postId} />
      </Box>
    </Box>
  )
}
