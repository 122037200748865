import { useMutation } from 'react-query'
import { paths } from '../../api-types/pp-api-types'
import { webGatewayApi } from '../../services/base'

type AmaPurchaseTaxesResponseData =
  paths['/payments/ama/questions/taxesandfees']['post']['responses']['200']['content']['application/json']

export default function useGetAmaPurchaseTaxes() {
  return useMutation({
    mutationFn: async ({
      questionId,
      paymentMethodId,
    }: {
      questionId: string
      paymentMethodId: string
    }) => {
      const { data, errors } =
        await webGatewayApi.post<AmaPurchaseTaxesResponseData>(
          `/payments/ama/questions/taxesandfees`,
          {
            data: { questionId, paymentMethodId },
          }
        )

      if (errors.length > 0) {
        throw new Error(errors[0].message)
      }

      return data
    },
  })
}
