import { Box, Heading } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { CenteredSpinner } from '../CenteredSpinner'
import PaymentMethod from './PaymentMethod'

export default function AmaPurchase() {
  const { signedIn } = useAuth()
  const [searchParams] = useSearchParams()
  const questionId = searchParams.get('questionId')

  if (!signedIn) {
    return <CenteredSpinner />
  }

  return (
    <Box py={6}>
      <Heading
        as="h2"
        fontSize={18}
        lineHeight="22px"
        fontWeight={500}
        color={'primary.950'}
      >
        Payment Method
      </Heading>
      <Box my={4}>
        <PaymentMethod questionId={questionId} />
      </Box>
    </Box>
  )
}
