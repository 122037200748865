import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { login } from '../../services/authentication/login'
import { validateToken } from '../../services/authentication/validate'
import {
  getJwtToken,
  removeJwtToken,
  setJwtToken,
} from '../../utils/authentication'

interface UserJWTInfo {
  sub: string
  aud: string
  nbf: number
  iss: string
  realm: string
  exp: number
  iat: number
  jti: string
  username: string
}

export const AuthContext = createContext<{
  signedIn: boolean
  initialized: boolean
  user: UserJWTInfo
}>({
  signedIn: false,
  initialized: false,
  user: undefined,
})
export const AuthSetterContext = createContext<{
  handleLogin?(props: {
    username: string
    password: string
  }): ReturnType<typeof login>
  handleLogout?(): void
  handleTokenValidate?(props: {
    accessToken: string
  }): ReturnType<typeof validateToken>
}>({
  handleLogin: undefined,
  handleLogout: undefined,
  handleTokenValidate: undefined,
})

export const useAuth = () => {
  return useContext(AuthContext)
}
export const useAuthMethods = () => {
  return useContext(AuthSetterContext)
}

export const AuthProvider: React.FC = ({ children }) => {
  const [initialized, setInitialized] = useState(false)
  const [signedIn, setSignedIn] = useState(false)
  const [user, setUser] = useState<UserJWTInfo | null>(null)

  const handleLoginResponse = ({ response }) => {
    if (response.errors.length === 0 && response.data.access_token) {
      setJwtToken(response.data.access_token)
      setUser(
        JSON.parse(
          atob(response.data.access_token.split('.')[1])
        ) as UserJWTInfo
      )
      setSignedIn(true)
    }
  }

  const handleLogin = useCallback(async function handleLogin({
    username,
    password,
  }) {
    const response = await login({ username, password })

    handleLoginResponse({ response })

    return response
  },
  [])
  const handleLogout = useCallback(function handleLogout() {
    removeJwtToken()
    setUser(null)
    setSignedIn(false)
  }, [])

  const handleValidateResponse = ({ response, accessToken }) => {
    if (response.errors.length === 0 && response.data.userId) {
      setJwtToken(accessToken)
      setUser(JSON.parse(atob(accessToken.split('.')[1])) as UserJWTInfo)
      setSignedIn(true)

      return Promise.resolve(response)
    }

    return Promise.reject('Token invalid')
  }

  const handleTokenValidate = useCallback(async function handleTokenValidate({
    accessToken,
  }) {
    const response = await validateToken({ accessToken })
    return handleValidateResponse({ response, accessToken })
  },
  [])

  useEffect(() => {
    ;(async () => {
      const accessToken = getJwtToken()

      if (accessToken) {
        await handleTokenValidate({ accessToken })
      }

      setInitialized(true)
    })()
  }, [handleTokenValidate, setInitialized])

  const stateValues = { signedIn, initialized, user }
  const stateSetterValues = { handleLogin, handleLogout, handleTokenValidate }

  return (
    <AuthSetterContext.Provider value={stateSetterValues}>
      <AuthContext.Provider value={stateValues}>
        {children}
      </AuthContext.Provider>
    </AuthSetterContext.Provider>
  )
}
