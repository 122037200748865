const Text = {
  baseStyle: {
    color: 'var(--chakra-colors-primary-600)',
  },
  sizes: {
    normal: {
      fontSize: '13px',
      lineHeight: '18px',
      letterSpacing: '0.04em',
    },
  },
  defaultProps: {
    size: 'normal',
  },
}

export default Text
