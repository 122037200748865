import { Box, Text } from '@chakra-ui/react'
import { Heading } from '../Heading'

const ReturnPage = () => {
  return (
    <Box height={'100vh'}>
      <Box
        height={'400px'}
        backgroundImage={
          'https://s3.us-west-2.amazonaws.com/prod.qp.me/images/welcome.jpg'
        }
        backgroundSize={'contain'}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center center'}
        backgroundColor={'#fff'}
      ></Box>
      <Box p={4} backgroundColor={'#fff'}>
        <Heading type="h1" mb={3} textAlign="center">
          All done
        </Heading>

        <Text
          textAlign="center"
          fontSize="xl"
          lineHeight="24px"
          maxWidth={'400px'}
          margin={'0 auto'}
        >
          Close this window to return to the app
        </Text>
      </Box>
    </Box>
  )
}

export { ReturnPage }
