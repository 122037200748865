import { useEffect, useState } from 'react'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { Box } from '@chakra-ui/react'
import { useChannel } from '../../providers/ChannelProvider'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '../../utils/useQuery'
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from '@stripe/stripe-js'
import { getConfig } from '../../config'
import { CenteredSpinner } from '../CenteredSpinner'

const stripeKey = getConfig().stripe.publishableKey
const stripePromise = loadStripe(stripeKey)

export function NewPaymentMethodStatusStripe() {
  const stripe = useStripe()
  const navigate = useNavigate()
  const { channelIdentifier, channelOwnerName } = useChannel()
  const query = useQuery()
  // const subscriptionOptionIdQueryParam = query.get('subscriptionId')
  // const subscriberProfileId = query.get('subscriberProfileId')
  // const promoIdQueryParam = query.get('promoId')
  const setupIntentClientSecret = query.get('setup_intent_client_secret')

  // if we have a postId we are purchasing, if not, subscribing
  // NOTE: postId can be ama-purchase for purchasing a question as well
  const returnSlug = query.get('postId') ?? 'payment-method'
  const successSlug = query.get('postId')
    ? `${query.get('postId')}/purchase`
    : 'payment-review'

  const [status, setStatus] = useState<{ message: string; code: string }>({
    message: null,
    code: null,
  })

  useEffect(() => {
    if (!stripe) {
      return
    }

    if (!setupIntentClientSecret) {
      navigate(
        `/${channelOwnerName}/${channelIdentifier}/${returnSlug}?${query.toString()}`
      )
      return
    }

    stripe
      .retrieveSetupIntent(setupIntentClientSecret)
      .then(({ setupIntent }) => {
        switch (setupIntent.status) {
          case 'succeeded':
            setStatus({
              message: 'Success! Your payment method has been saved.',
              code: setupIntent.status,
            })
            query.set('pm', setupIntent.payment_method)
            // Added on redirect after adding a payment method
            query.delete('setup_intent')
            query.delete('setup_intent_client_secret')
            query.delete('redirect_status')
            setTimeout(() => {
              navigate(
                `/${channelOwnerName}/${channelIdentifier}/${successSlug}?${query.toString()}`
              )
            }, 3000)
            break
          case 'processing': {
            setStatus({
              message:
                "Processing payment details. We'll update you when processing is complete.",
              code: setupIntent.status,
            })
            break
          }
          case 'requires_payment_method': {
            setStatus({
              message:
                'Failed to process payment details. Please try another payment method.',
              code: setupIntent.status,
            })
            setTimeout(() => {
              navigate(
                `/${channelOwnerName}/${channelIdentifier}/${returnSlug}?${query.toString()}`
              )
            }, 3000)
            break
          }
          default:
            setStatus({
              message: 'Something went wrong.',
              code: setupIntent.status,
            })
            setTimeout(() => {
              navigate(
                `/${channelOwnerName}/${channelIdentifier}/${returnSlug}?${query.toString()}`
              )
            }, 3000)
            break
        }
      })
  }, [
    channelIdentifier,
    channelOwnerName,
    navigate,
    query,
    returnSlug,
    setupIntentClientSecret,
    stripe,
    successSlug,
  ])

  return (
    <>
      {status && <p>{status.message}</p>}
      <CenteredSpinner />
    </>
  )
}

export function NewPaymentMethodStatus() {
  const query = useQuery()
  const setupIntentClientSecret = query.get('setup_intent_client_secret')
  const appearance: Appearance = {
    theme: 'stripe',
  }
  const options: StripeElementsOptions = {
    clientSecret: setupIntentClientSecret,
    appearance,
  }

  return (
    <Box
      maxWidth={450}
      margin="0 auto"
      p={4}
      borderTopWidth={1}
      borderTopColor={'boxBorder.default'}
    >
      {setupIntentClientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <NewPaymentMethodStatusStripe />
        </Elements>
      )}
    </Box>
  )
}
