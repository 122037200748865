import { components as ChannelsApiComponents } from '../api-types/channels-api-types'

export const getPriceDescription = (
  priceOption?: ChannelsApiComponents['schemas']['SubscriptionPriceOption'],
  discount?: number
) => {
  return priceOption &&
    priceOption.price !== undefined &&
    priceOption.recurrence !== undefined
    ? priceOption.price
      ? discount
        ? ` $${(
            priceOption.price -
            (priceOption.price / 100) * discount
          ).toFixed(2)}`
        : `$${priceOption.price}`
      : 'Free'
    : ''
}

export const getPriceRecurrence = (
  priceOption?: ChannelsApiComponents['schemas']['SubscriptionPriceOption'],
  discount?: number
) => {
  return priceOption &&
    priceOption.price !== undefined &&
    priceOption.recurrence !== undefined
    ? priceOption.recurrence === 'onetime'
      ? ''
      : ` / ` +
        (priceOption.recurrenceIntervalCount > 1
          ? priceOption.recurrenceIntervalCount +
            ' ' +
            priceOption.recurrence +
            's'
          : priceOption.recurrence)
    : ''
}
