import { useQuery } from 'react-query'
import { paths } from '../api-types/channels-api-types'
import { webGatewayApi } from '../services/base'

type GetChannelPostsResponseData =
  paths['/channels/pub/profile/{profile}/{identifier}/posts']['get']['responses']['200']['content']['application/json']

interface UseGetChannelPostsParams {
  profileId: string
  channelId: string
}

export default function useGetChannelPosts({
  profileId,
  channelId,
}: UseGetChannelPostsParams) {
  return useQuery({
    queryKey: ['public-channel-posts', profileId, channelId],
    queryFn: async () => {
      const { data, errors } =
        await webGatewayApi.get<GetChannelPostsResponseData>(
          `/channels/pub/profile/${profileId}/${channelId}/posts`
        )

      if (errors.length > 0) {
        throw new Error(errors[0].message)
      }

      return data
    },
    staleTime: 10 * 60 * 1000, // 10min
  })
}
