import * as React from 'react'
import { Box, Grid, useBreakpointValue } from '@chakra-ui/react'
import { PostItem } from '../PostItem'

export type PostItemGridProps = {
  posts: { imageSrc: string; imageAlt: string }[]
}

// Width and Height are currently hardcoded 151,151
// grid-template-columns: repeat( auto-fit, minmax(250px, 300px) )
// Going forward we need to control the dimensions of the profile background picture server side
const PostItemGrid: React.FC<PostItemGridProps> = ({ posts }) => {
  const gridColumnCount = useBreakpointValue({ base: 2 })
  const gridColumnGap = useBreakpointValue({ base: 5 })
  const gridRowGap = useBreakpointValue({ base: 3 })

  return (
    <Grid
      templateColumns={`repeat(${gridColumnCount}, 1fr)`}
      columnGap={gridColumnGap}
      rowGap={gridRowGap}
      placeItems={'start'}
    >
      {posts.map((post) => (
        <Box key={post.imageSrc}>
          <PostItem imageSrc={post.imageSrc} imageAlt={post.imageAlt} />
        </Box>
      ))}
    </Grid>
  )
}

export { PostItemGrid }
