import { Link, useParams } from 'react-router-dom'
import useGetProfile from '../../hooks/useGetProfile'
import Header from './Header'
import classes from './ProfilePage.module.css'
import ChannelRow from './ChannelRow'
import AmaRow from './AmaRow'
import PublicLayout from '../layout/PublicLayout'
import { useQuery } from '../../utils/useQuery'
import { getConfig } from '../../config'
//import { isAndroid, isIOS } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'

const ProfilePage = () => {
  const { channelOwnerName, channelIdentifier, postId } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
    postId: string
  }>()

  const { data } = useGetProfile({
    channelOwnerName,
  })

  const query = useQuery()
  const branchChannel = query.get('channel')
    ? 'channel=' + query.get('channel')
    : 'channel=' +
      (channelOwnerName ? channelOwnerName : '') +
      (channelIdentifier ? '/' + channelIdentifier : '') +
      (postId ? '/' + postId : '')
  const branchCode = query.get('code') ? '&code=' + query.get('code') : ''
  const branchSource = query.get('s') ? '&s=' + query.get('s') : ''
  const branchReferrer = query.get('r') ? '&r=' + query.get('r') : ''

  const branchLink =
    getConfig().branch.branchLink +
    `?${branchChannel}${branchCode}${branchSource}${branchReferrer}`

  const playStoreUp = true
  const androidURL = playStoreUp ? branchLink : '/android'

  // async function copyToClipboard() {
  //   if (isIOS || isAndroid) {
  //     //await navigator.clipboard.writeText(branchLink)
  //     window.location.replace(branchLink)
  //   }
  // }

  // copyToClipboard()

  if (!data) {
    // TODO: fallback state
    return null
  }

  const {
    profile: { displayName, username, bio, imageUrl },
    channels,
    amaChannel,
  } = data

  return (
    <>
      <Helmet>
        <meta property="og:title" content={displayName} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:description" content={bio} />
      </Helmet>
      <PublicLayout branchLink={branchLink} androidURL={androidURL}>
        <>
          <Header
            displayName={displayName}
            username={username}
            bio={bio}
            imageUrl={imageUrl}
          />

          {amaChannel ? (
            <>
              <hr className={classes.divider} />
              <div>
                <Link
                  to={`/${channelOwnerName}/${amaChannel.identifier}`}
                  className={classes['section-heading']}
                >
                  Ask Me Anything (AMAs)
                </Link>
                <AmaRow
                  channel={amaChannel}
                  channelOwnerName={channelOwnerName}
                />
              </div>
            </>
          ) : null}

          {channels && channels.length > 0 ? (
            <>
              <hr className={classes.divider} />
              <div>
                <h5 className={classes['section-heading']}>Channels</h5>
                {channels.map((channel) => {
                  const { id } = channel

                  return (
                    <ChannelRow
                      key={id}
                      channel={channel}
                      channelOwnerName={channelOwnerName}
                    />
                  )
                })}
              </div>
            </>
          ) : null}
        </>
      </PublicLayout>
    </>
  )
}

export { ProfilePage }
