import ReactPlayer from 'react-player'
import Card from '../../Card'
import { PostTypeProps } from '../types'

// interface AudioProps {
//   post: components['schemas']['PublicWebPost']
//   to: To
// }

export default function Audio({
  post,
  detailView = false,
  ...rest
}: PostTypeProps) {
  const {
    title: { text },
    thumbs,
    hlsDownloadLink,
  } = post
  const thumbUrl = thumbs[0].downloadLink

  const bgElement = detailView ? (
    <ReactPlayer url={hlsDownloadLink} controls width="100%" height="100%" />
  ) : (
    <img alt={text} src={thumbUrl} />
  )

  return <Card className="audio" bgCoverElement={bgElement} {...rest} />
}
