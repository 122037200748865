import { QueryClient, QueryClientProvider } from 'react-query'
import { ChakraProvider, Container } from '@chakra-ui/react'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import ChannelSubscriptionOptionsPage from './pages/ChannelSubscriptionOptionsPage'
import { AuthProvider } from './providers/AuthProvider'
import Fonts from './theme/Global'
import theme from './theme'
import { OnboardingSuccess } from './components/OnboardingSuccess'
import {
  ReturnPage,
  DonationPage,
  SubscriptionPage,
} from './components/ReturnPage'
import { ConnectPage } from './components/ConnectPage'
import { ReauthPage } from './components/ReauthPage/ReauthPage'
import { AndroidPage } from './components/AndroidPage'
import './theme/app.css'
import { ProfilePage } from './components/ProfilePage'
import { HelmetProvider } from 'react-helmet-async'

import ReactGA from 'react-ga4'

ReactGA.initialize('G-XW97W3XHW5')

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000, // 2min
    },
  },
})

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <AuthProvider>
            <Fonts />
            <BrowserRouter>
              <Routes>
                <Route
                  path="/:channelOwnerName/:channelIdentifier/*"
                  element={<ChannelSubscriptionOptionsPage />}
                />
                <Route
                  element={
                    <Container className="page" m={0}>
                      <Outlet />
                    </Container>
                  }
                >
                  <Route
                    path="onboarding-success"
                    element={<OnboardingSuccess />}
                  />
                  <Route path="return" element={<ReturnPage />} />
                  <Route path="gift" element={<DonationPage />} />
                  <Route path="subscribe" element={<SubscriptionPage />} />
                  <Route path="reauth" element={<ReauthPage />} />
                  <Route path="connect" element={<ConnectPage />} />
                  <Route path="android" element={<AndroidPage />} />
                </Route>
                <Route path="/:channelOwnerName/" element={<ProfilePage />} />
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
