import { Link } from 'react-router-dom'
import Avatar from '../Avatar'
import classes from './PublicChannelPage.module.css'

interface HeaderProps {
  bgUrl: string
  avatarUrl: string
  channelOwnerName: string
  channelOwnerIdentifier: string
}

export default function Header({
  avatarUrl,
  channelOwnerName,
  channelOwnerIdentifier,
  bgUrl,
}: HeaderProps) {
  return (
    <div className={classes.header}>
      <img className={classes.header__bg} src={bgUrl} alt={channelOwnerName} />
      <div className={classes.header__content}>
        <Link
          className={classes['profile-Link']}
          to={`/${channelOwnerIdentifier}`}
        >
          <Avatar url={avatarUrl} alt={channelOwnerName} width={60} />
          {channelOwnerName}{' '}
          <span className={classes['profile-Link__sub-text']}>(Owner)</span>
        </Link>
      </div>
    </div>
  )
}
