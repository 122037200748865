import { Helmet } from 'react-helmet-async'
import { useQuery } from '../../utils/useQuery'
import { getConfig } from '../../config'
import { useParams } from 'react-router-dom'
import useGetChannel from '../../hooks/useGetChannel'
import ShareBtn from '../ShareBtn'
import Header from './Header'
import LatestPosts from './LatestPosts'
import classes from './PublicChannelPage.module.css'

export default function PublicChannelPage() {
  const query = useQuery()
  const { channelOwnerName, channelIdentifier } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
    postId: string
  }>()

  const { data } = useGetChannel({
    profileId: channelOwnerName,
    channelId: channelIdentifier,
  })

  if (!data) {
    return null
  }

  const { selectedChannel, profile } = data
  const { channelImageUrl, description, name } = selectedChannel
  const { displayName, imageUrl, username } = profile

  const branchChannel = query.get('channel')
    ? 'channel=' + query.get('channel')
    : 'channel=' +
      (channelOwnerName ? channelOwnerName : '') +
      (channelIdentifier ? '/' + channelIdentifier : '')

  const branchCode = query.get('code') ? '&code=' + query.get('code') : ''
  const branchSource = query.get('s') ? '&s=' + query.get('s') : ''
  const branchReferrer = query.get('r') ? '&r=' + query.get('r') : ''

  const branchLink =
    getConfig().branch.branchLink +
    `?${branchChannel}${branchCode}${branchSource}${branchReferrer}`

  const isAMA = selectedChannel.channelType === 'AMA'

  return (
    <>
      <Helmet>
        <meta property="og:title" content={name} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={channelImageUrl} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div>
        <Header
          bgUrl={channelImageUrl}
          avatarUrl={imageUrl}
          channelOwnerName={displayName}
          channelOwnerIdentifier={username}
        />
        <div className={classes.content}>
          {name && <h3 className={classes.content__heading}>{name}</h3>}
          {description && <p>{description}</p>}
        </div>
        <div className={classes.actions}>
          {isAMA && (
            <a href={branchLink}>
              <button className={`${classes.btn_round} btn`}>Ask Me</button>
            </a>
          )}
          <ShareBtn>Share</ShareBtn>
        </div>
        <p className={classes['section-heading']}>Latest posts</p>
        <LatestPosts
          channel={selectedChannel}
          channelOwnerName={channelOwnerName}
        />
      </div>
    </>
  )
}
