import { ProfilePicture, ProfilePictureProps } from '../ProfilePicture'
import { Box, Grid, useBreakpointValue } from '@chakra-ui/react'
import { useQuery } from '../../utils/useQuery'

export interface ChannelHeaderProps extends ProfilePictureProps {
  profileTitle
  profileDescription
  channelTitle
}

const ChannelHeader = ({
  channelPictureSrc,
  channelPictureAlt,
  profilePictureSrc,
  profileTitle,
  channelTitle,
  profileDescription,
}: ChannelHeaderProps): JSX.Element => {
  const channelHeaderGridStyles = useBreakpointValue({
    base: {
      templateColumns: '1fr',
    },
  })

  const query = useQuery()
  const showDescription = !query.get('token') && !query.get('subscriptionId')
  const isPostPurchase = query.get('postId')

  /**
   * We use the same routes for creating payment methods.
   * Because of this we dont want to show the channel
   * header if we are in the post purchase flow
   */
  if (isPostPurchase) {
    return null
  }

  return (
    <Grid {...channelHeaderGridStyles}>
      <ProfilePicture
        {...{
          channelPictureSrc,
          channelPictureAlt,
          profilePictureSrc,
          profileTitle,
        }}
      />
      <div className="title">{channelTitle}</div>
      {showDescription ? (
        <Box>
          <p className="text-desc">{profileDescription}</p>
        </Box>
      ) : (
        ''
      )}
    </Grid>
  )
}

export { ChannelHeader }
