export function getJwtToken() {
  return sessionStorage.getItem('access_token')
}

export function setJwtToken(token) {
  sessionStorage.setItem('access_token', token)
}
export function removeJwtToken() {
  sessionStorage.removeItem('access_token')
}
