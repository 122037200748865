import classNames from 'classnames'
import { cloneElement, ReactElement } from 'react'
import { Link, To } from 'react-router-dom'
import classes from './Card.module.css'

const cx = classNames.bind(classes)

type BaseProps = {
  children?: ReactElement
  bgCoverElement?: JSX.Element
  className?: string
  noHover?: boolean
  detailView?: boolean
  aspectRatio?: AspectRatio
  isActive?: boolean
}

type AspectRatio = '2:3' | '9:16' | '3:4'

type LinkProps = BaseProps & {
  as?: 'Link'
  to: To
}

type DivProps = BaseProps & {
  as: 'div'
  to?: never
}

export type CardProps = LinkProps | DivProps

export default function Card({
  children,
  to,
  bgCoverElement,
  className,
  noHover = false,
  as = 'Link',
  aspectRatio = '2:3',
  isActive = false,
}: CardProps & BaseProps) {
  const Component = as === 'div' ? 'div' : Link
  const ratios = aspectRatio.split(':')
  const paddingTop = (Number(ratios[1]) / Number(ratios[0])) * 100

  return (
    <Component
      to={to}
      className={cx(classes.wrapper, className)}
      style={{
        paddingTop: `${paddingTop}%`,
      }}
    >
      {bgCoverElement &&
        cloneElement(bgCoverElement, {
          className: cx(bgCoverElement.props.className, classes.cover),
        })}
      {children
        ? cloneElement(children, {
            className: cx(
              children.props.className,
              classes.cover,
              classes.child
            ),
          })
        : null}
      {(isActive || !noHover) && (
        <div
          className={cx(classes.overlay, {
            [classes['overlay--active']]: isActive,
          })}
        />
      )}
    </Component>
  )
}
