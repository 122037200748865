import { Link } from 'react-router-dom'
import { components } from '../../../api-types/channels-api-types'
import Avatar from '../../Avatar'
import classes from './PublicPostPage.module.css'

interface PostedByProps {
  post: components['schemas']['PublicWebPost']
}

export default function AmaAuthor({ post }: PostedByProps) {
  const { publisherUsername, profileDisplayName, profileImageUrl } = post

  return (
    <div className={classes.author}>
      <div>
        <Link to={`/${publisherUsername}`}>
          <Avatar alt={profileDisplayName} url={profileImageUrl} width={60} />
        </Link>
      </div>
      <div>
        <div className={classes.author__name}>
          <Link to={`/${publisherUsername}`}>{profileDisplayName}</Link>
        </div>
        <div className={classes.author__handle}>{publisherUsername}</div>
      </div>
    </div>
  )
}
