import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { components } from '../../../api-types/channels-api-types'
import Avatar from '../../Avatar'
import classes from './PublicPostPage.module.css'

interface PostedByProps {
  post: components['schemas']['PublicWebPost']
}

export default function PostedBy({ post }: PostedByProps) {
  const createdAt = format(new Date(post.timeCreated), 'MMM d, yyyy')

  return (
    <div className={classes['posted-by']}>
      <div>
        <Link to={`/${post.publisherUsername}`}>
          <Avatar
            alt={post.profileDisplayName}
            url={post.profileImageUrl}
            width={36}
          />
        </Link>
      </div>
      <div>
        Posted By{' '}
        <Link
          className={classes['posted-by__cta']}
          to={`/${post.publisherUsername}`}
        >
          {post.profileDisplayName}
        </Link>{' '}
        {createdAt}
      </div>
    </div>
  )
}
