import { Box, Container, Heading, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { CenteredSpinner } from '../CenteredSpinner'
import useGetPost from './useGetPost'

interface PostPurchaseTemplateProps {
  children?: ReactElement
}

export default function PostPurchaseTemplate({
  children,
}: PostPurchaseTemplateProps) {
  const { postId, channelOwnerName, channelIdentifier } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
    postId: string
  }>()
  const { data, isError, isLoading } = useGetPost({
    postId,
    channelOwnerName,
    channelIdentifier,
  })

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (isError || !data) {
    return (
      <Box py={6} color="red.600">
        Something went wrong. Please contact support.
      </Box>
    )
  }

  const { post } = data
  const formatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  }).format(post.postProductOption.priceOption.price)

  return (
    <Container className="page" m={0}>
      <Box pt={4} pb={2}>
        <Heading
          as="h2"
          fontSize={22}
          lineHeight={1.3}
          fontWeight={600}
          color={'primary.950'}
          pb={2}
        >
          {post.title.text}
        </Heading>
        <Text
          mt={2}
          fontSize={17}
          lineHeight={1.2}
          fontWeight={400}
          color={'primary.950'}
        >
          {post.postProductOption.name}
        </Text>
      </Box>
      <Box pt={2} pb={2} borderY={1} borderStyle="solid" borderColor="#EDEEEF">
        <Text mt={2} fontSize={17} lineHeight={1.2} color={'primary.950'}>
          One-Time Purchase
        </Text>
        <Text
          mt={2}
          fontSize={28}
          lineHeight={1.2}
          fontWeight={600}
          color={'primary.950'}
        >
          {formatted}
        </Text>
      </Box>
      {children}
    </Container>
  )
}
