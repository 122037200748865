// theme/index.js
import { extendTheme } from '@chakra-ui/react'

// Global style overrides
import styles from './styles'

// Foundational style overrides
// import borders from './foundations/borders'

// Component style overrides
import Heading from './components/heading'
import Text from './components/text'
import Button from './components/button'
import Input from './components/input'
import Table from './components/table'

const overrides = {
  ...styles,
  //   borders,
  // Other foundational style overrides go here
  components: {
    Heading,
    Input,
    Text,
    Button,
    Table,
    // Other components go here
  },
}

export default extendTheme(overrides)
