import { FormEventHandler, useEffect, useState } from 'react'
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js'
import { Button, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { useChannel } from '../../providers/ChannelProvider'
import { loadStripe } from '@stripe/stripe-js'
import { webGatewayApi } from '../../services/base'
import { getConfig } from '../../config'
import { CenteredSpinner } from '../CenteredSpinner'

const stripeKey = getConfig().stripe.publishableKey
const stripePromise = loadStripe(stripeKey)

const useCreatePaymentMethod = () => {
  const [newPaymentMethodClientSecret, setNewPaymentMethodClientSecret] =
    useState<string>()
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const { data, errors } = await webGatewayApi.get<{
        status: string
        stripeClientSecret: string
      }>('/payments/createPaymentMethod')

      if (errors.length > 0) {
        console.error(...errors)
        setHasError(true)
      }

      if (data.stripeClientSecret) {
        setNewPaymentMethodClientSecret(data.stripeClientSecret)
      }
      setLoading(false)
    })()
  }, [])

  return { loading, newPaymentMethodClientSecret, hasError }
}

interface CreatePaymentMethodProps {
  checkoutParams: Record<string, string>
}

function CreatePaymentMethodForm({ checkoutParams }: CreatePaymentMethodProps) {
  const stripe = useStripe()
  const elements = useElements()
  const { channelIdentifier, channelOwnerName } = useChannel()
  const params = new URLSearchParams(checkoutParams)

  const [status, setStatus] = useState<{ message: string; code: string }>({
    message: null,
    code: null,
  })
  const [isFormLoading, setIsFormLoading] = useState(true)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const handleFormReady = () => {
    setIsFormLoading(false)
  }

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsSubmitLoading(true)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${
          window.location.origin
        }/${channelOwnerName}/${channelIdentifier}/new-payment-method-status?${params.toString()}`,
      },
    })

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setStatus({ message: error.message, code: error.type as string })
    } else {
      setStatus({ message: 'An unexpected error occured.', code: error.type })
    }

    setIsSubmitLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        isInvalid={status.code?.includes('error')}
        id="payment-form"
        pb={200}
      >
        {isFormLoading && <CenteredSpinner />}
        {<PaymentElement id="payment-element" onReady={handleFormReady} />}
        {!isFormLoading && (
          <Button
            mt={4}
            disabled={isSubmitLoading || !stripe || !elements}
            id="submit"
            type="submit"
            variant="primary"
          >
            {isSubmitLoading ? (
              <CenteredSpinner />
            ) : (
              'Create new payment method'
            )}
          </Button>
        )}
        <FormErrorMessage>{status.message}</FormErrorMessage>
      </FormControl>
    </form>
  )
}

export function CreatePaymentMethod({
  checkoutParams,
}: CreatePaymentMethodProps) {
  const {
    loading: loadingNewPaymentMethodClientSecret,
    newPaymentMethodClientSecret,
    hasError,
  } = useCreatePaymentMethod()

  if (loadingNewPaymentMethodClientSecret) {
    return <CenteredSpinner />
  }

  if (hasError || !newPaymentMethodClientSecret) {
    return <p>An error occured while loading the new payment method form.</p>
  }

  return (
    <Elements
      options={{
        clientSecret: newPaymentMethodClientSecret,
        appearance: {
          theme: 'stripe',
        },
      }}
      stripe={stripePromise}
    >
      <CreatePaymentMethodForm checkoutParams={checkoutParams} />
    </Elements>
  )
}
