import classnames from 'classnames'
import Card from '../../Card'
import classes from '../PostCard.module.css'
import { PostTypeProps } from '../types'

const cx = classnames.bind(classes)

// interface TextProps {
//   post: components['schemas']['PublicWebPost']
//   to: To
// }

export default function Text({
  post,
  detailView = false,
  ...rest
}: PostTypeProps) {
  const { properties } = post

  // Also serves as the fallback for PostType === Unknown so need some fallback values
  const body = properties?.body
  const startColor = properties?.firstColor ?? 'rgb(240, 147, 251)'
  const endColor = properties?.secondColor ?? 'rgb(245, 87, 108)'

  return (
    <Card
      className="text"
      bgCoverElement={
        <div
          style={{
            background: `linear-gradient(135deg, ${startColor} 0%, ${endColor} 100%)`,
          }}
        />
      }
      {...rest}
    >
      <div
        className={cx(classes['post-text'], {
          [classes['post-text--detail']]: detailView,
        })}
      >
        <span className={cx(classes['post-text__content'])}>{body}</span>
      </div>
    </Card>
  )
}
