import { webGatewayApi } from '../base'

type ProfileImage = {
  pictureDownloadLink?: string
  pictureId?: string
  profileId?: string
}

export const getProfilePicture = async ({ accountId, profileId }) => {
  const response = await webGatewayApi.get<ProfileImage>(
    `/accounts/${accountId}/profiles/${profileId}/picture`
  )

  return response
}
