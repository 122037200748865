const Input = {
  baseStyle: {},
  variants: {
    filled: {
      field: {
        borderRadius: 'lg',
        paddingLeft: 3,
        color: 'primary.600',
        _placeholder: {
          color: 'primary.600',
        },
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'filled',
  },
}

export default Input
