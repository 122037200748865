import { To } from 'react-router-dom'
import Card from '../Card'
import classes from './ChannelCard.module.css'

interface ChannelCardProps {
  label: string
  to: To
  imageUrl: string
}

export default function ChannelCard({ label, to, imageUrl }: ChannelCardProps) {
  return (
    <Card noHover to={to} bgCoverElement={<img alt={label} src={imageUrl} />}>
      <div className={classes.overlay}>
        <span className={classes.title}>{label}</span>
      </div>
    </Card>
  )
}
