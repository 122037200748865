import { Config, IConfigEnv } from './types'

const configKeys: (keyof IConfigEnv)[] = [
  'REACT_APP_CHANNELS_API_BASE_URL',
  'REACT_APP_CORE_API_BASE_URL',
  'REACT_APP_AUTHENTICATION_API_BASE_URL',
  'REACT_APP_PAYMENTS_API_BASE_URL',
  'REACT_APP_STRIPE_PUBLISHABLE_KEY',
  'REACT_APP_WEB_GATEWAY_API_BASE_URL',
  'REACT_APP_BRANCH_LINK',
]

export function getConfig(): Config {
  const missingKeys: string[] = []
  configKeys.forEach((configKey) => {
    if (!process.env.REACT_APP_CHANNELS_API_BASE_URL) {
      missingKeys.push(configKey)
    }
  })

  if (missingKeys.length) {
    throw new Error(`Missing configuration ${JSON.stringify(missingKeys)}`)
  }

  return {
    api: {
      core: {
        baseURL: process.env.REACT_APP_CORE_API_BASE_URL as string,
      },
      channels: {
        baseURL: process.env.REACT_APP_CHANNELS_API_BASE_URL as string,
      },
      authentication: {
        baseURL: process.env.REACT_APP_AUTHENTICATION_API_BASE_URL as string,
      },
      payments: {
        baseURL: process.env.REACT_APP_PAYMENTS_API_BASE_URL as string,
      },
      webGateway: {
        baseURL: process.env.REACT_APP_WEB_GATEWAY_API_BASE_URL as string,
      },
    },
    stripe: {
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
    },
    branch: {
      branchLink: process.env.REACT_APP_BRANCH_LINK as string,
    },
  }
}
