import { components } from '../../api-types/channels-api-types'
import useGetChannelPosts from '../../hooks/useGetChannelPosts'
import { PostCard } from '../Cards'
import classes from './PublicChannelPage.module.css'

interface LatestPostsProps {
  channel: components['schemas']['PublicWebChannel']
  channelOwnerName: string
}

export default function LatestPosts({
  channel,
  channelOwnerName,
}: LatestPostsProps) {
  const { identifier } = channel

  const { data } = useGetChannelPosts({
    profileId: channelOwnerName,
    channelId: identifier,
  })

  if (!data) {
    return null
  }

  return (
    <div className={classes.latest}>
      {data.map((post) => {
        const { id, mediaPostType } = post

        return (
          <PostCard
            aspectRatio={mediaPostType === 'AMA' ? '3:4' : '2:3'}
            key={id}
            post={post}
            to={`/${channelOwnerName}/${identifier}/${id}`}
          />
        )
      })}
    </div>
  )
}
