import { useQuery } from 'react-query'
import { paths } from '../../api-types/pp-api-types'
import { webGatewayApi } from '../../services/base'

type AmaPurchaseResponseData =
  paths['/payments/ama/questions/{questionId}/details']['get']['responses']['200']['content']['application/json']

export default function useGetAmaPurchaseDetails(questionId: string) {
  return useQuery({
    queryFn: async () => {
      const { data, errors } = await webGatewayApi.get<AmaPurchaseResponseData>(
        `/payments/ama/questions/${questionId}/details`
      )

      if (errors.length > 0) {
        throw new Error(errors[0].message)
      }

      return data
    },
  })
}
