import { useNavigate } from 'react-router-dom'
import { PaymentMethodOption } from '.'
import { useChannel } from '../../providers/ChannelProvider'
import { useQuery } from '../../utils/useQuery'

export function useNavigateToPaymentReview(
  selectedPaymentMethod: PaymentMethodOption
) {
  const query = useQuery()
  const navigate = useNavigate()
  const { channelIdentifier, channelOwnerName } = useChannel()
  const subscriptionOptionIdQueryParam = query.get('subscriptionId')
  const subscriberProfileId = query.get('subscriberProfileId')
  const promoIdQueryParam = query.get('promoId')
  const navigateToPaymentReview = () => {
    navigate(
      `/${channelOwnerName}/${channelIdentifier}/payment-review?subscriptionId=${subscriptionOptionIdQueryParam}&pm=${selectedPaymentMethod.value}&promoId=${promoIdQueryParam}&subscriberProfileId=${subscriberProfileId}`
    )
  }
  return {
    navigateToPaymentReview,
  }
}
