import { getConfig } from '../config'
import { getJwtToken } from '../utils/authentication'

const {
  api: {
    channels: { baseURL: channelsApiBaseUrl },
    authentication: { baseURL: authenticationApiBaseUrl },
    payments: { baseURL: paymentsApiBaseUrl },
    webGateway: { baseURL: webGatewayBaseUrl },
  },
} = getConfig()

export interface IBaseAPIResponse<T> {
  errors: { message: string }[]
  data: T | null
}

export interface ICustomRequestInit extends RequestInit {
  data?: Record<string, unknown>
}

const apiFetch =
  (baseURL: string, method: string) =>
  async <ResponseData>(
    path: string,
    init?: ICustomRequestInit
  ): Promise<IBaseAPIResponse<ResponseData>> => {
    const jwtToken = getJwtToken()

    return fetch(`${baseURL}${String(path)}`, {
      ...init,
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        ...(jwtToken
          ? {
              Authorization: `Bearer ${jwtToken}`,
            }
          : undefined),
        ...init?.headers,
      },
      body: init?.data ? JSON.stringify(init.data) : init?.body,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 400) {
          throw new Error(data.error || data.message)
        } else if (data.status > 400) {
          throw new Error('An error has occured. Please contact support.')
        }
        return data
      })
      .then((data: ResponseData) => ({
        data,
        errors: [],
      }))
      .catch((error) => {
        console.error(error)

        return {
          errors: [{ message: error.message }],
          data: null,
        }
      })
  }

export const channelsApi = {
  get: apiFetch(channelsApiBaseUrl, 'GET'),
  put: apiFetch(channelsApiBaseUrl, 'PUT'),
  post: apiFetch(channelsApiBaseUrl, 'POST'),
  delete: apiFetch(channelsApiBaseUrl, 'DELETE'),
}
export const paymentsApi = {
  get: apiFetch(paymentsApiBaseUrl, 'GET'),
  put: apiFetch(paymentsApiBaseUrl, 'PUT'),
  post: apiFetch(paymentsApiBaseUrl, 'POST'),
  delete: apiFetch(paymentsApiBaseUrl, 'DELETE'),
}
export const authenticationApi = {
  get: apiFetch(authenticationApiBaseUrl, 'GET'),
  put: apiFetch(authenticationApiBaseUrl, 'PUT'),
  post: apiFetch(authenticationApiBaseUrl, 'POST'),
  delete: apiFetch(authenticationApiBaseUrl, 'DELETE'),
}
export const webGatewayApi = {
  get: apiFetch(webGatewayBaseUrl, 'GET'),
  put: apiFetch(webGatewayBaseUrl, 'PUT'),
  post: apiFetch(webGatewayBaseUrl, 'POST'),
  delete: apiFetch(webGatewayBaseUrl, 'DELETE'),
}
