import classes from './Avatar.module.css'

interface AvatarProps {
  alt: string
  url: string
  width: number | string
}

export default function Avatar({ url, width, alt }: AvatarProps) {
  return (
    <div
      className={classes.avatar}
      style={{
        width,
        height: width,
      }}
    >
      <img className={classes.avatar__img} src={url} alt={alt} />
    </div>
  )
}
