import { Heading } from '@chakra-ui/react'

export default function AmaPurchaseSuccess() {
  return (
    <div className="centered padded">
      <div className="icon-round">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.6666 8L12.7777 22.8882L5.33325 15.4444"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <Heading
        as="h1"
        fontSize={28}
        lineHeight={1.3}
        fontWeight={600}
        color={'primary.950'}
        my="16px"
      >
        Success!
      </Heading>
      <p className="p-mid">
        We’ll let you know when they answer your question. Close this window to
        return to My Questions.
      </p>
    </div>
  )
}
