import { Button } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CenteredSpinner } from '../CenteredSpinner'
import { CreatePaymentMethod } from '../SubscriptionPayment'
import { SelectPaymentMethods } from '../SubscriptionPayment/SelectPaymentMethod'
import { usePaymentMethods } from '../SubscriptionPayment/usePaymentMethods'

interface PaymentMethodProps {
  questionId: string
}

export default function PaymentMethod({ questionId }: PaymentMethodProps) {
  // const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    loading,
    paymentMethods,
    selectedPaymentMethod,
    selectedIsNew,
    setSelectedPaymentMethod,
    hasError,
  } = usePaymentMethods()

  // const purchaserProfileId = searchParams.get('purchaserProfileId')
  const checkoutParams = {
    // purchaserProfileId,
    pm: selectedPaymentMethod?.value,
    questionId,
    postId: 'ama-purchase', // So we come back to ama purchase flow
  }

  if (loading) {
    return <CenteredSpinner />
  }

  if (hasError) {
    return <p>An error occured while loading your payment methods.</p>
  }

  if (selectedIsNew) {
    return <CreatePaymentMethod checkoutParams={checkoutParams} />
  }

  return (
    <>
      <SelectPaymentMethods
        value={selectedPaymentMethod}
        isDisabled={paymentMethods.length < 2}
        paymentMethods={paymentMethods}
        onChange={(newValue) => {
          setSelectedPaymentMethod(newValue)
        }}
      />
      {selectedPaymentMethod && !selectedIsNew ? (
        <Button
          mt={1}
          disabled={!selectedPaymentMethod}
          type="button"
          onClick={(e) => {
            e.stopPropagation()
            const pathname = window.location.pathname
            const params = new URLSearchParams(checkoutParams)
            navigate(`${pathname}/purchase?${params.toString()}`)
          }}
          variant="primary"
        >
          Review fees
        </Button>
      ) : null}
    </>
  )
}
