import { paths } from '../../api-types/channels-api-types'
import { webGatewayApi } from '../../services/base'
import { useQuery } from 'react-query'

type GetChannelPostByIdentifierResponseData =
  paths['/channels/{channelOwnerName}/{channelIdentifier}/{mediaId}']['get']['responses']['200']['content']['application/json']

interface UseGetPostParams {
  postId: string
  channelOwnerName: string
  channelIdentifier: string
}

export default function useGetPost({
  postId,
  channelOwnerName,
  channelIdentifier,
}: UseGetPostParams) {
  return useQuery(['media', { postId }], async () => {
    const { data, errors } =
      await webGatewayApi.get<GetChannelPostByIdentifierResponseData>(
        `/channels/${channelOwnerName}/${channelIdentifier}/${postId}`
      )

    if (errors.length > 0) {
      throw new Error(errors[0].message)
    }

    return data
  })
}
