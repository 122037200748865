import React, { PropsWithChildren } from 'react'
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from '@chakra-ui/react'

export interface HeadingProps extends ChakraHeadingProps {
  type: 'h1' | 'h2'
}

const Heading = ({
  type,
  children,
  ...props
}: PropsWithChildren<HeadingProps>): JSX.Element => (
  <ChakraHeading as={type} variant={type} {...props}>
    {children}
  </ChakraHeading>
)

export { Heading }
