import Card from '../../Card'
import { PostTypeProps } from '../types'
import ReactPlayer from 'react-player'

// interface ImageProps {
//   post: components['schemas']['PublicWebPost']
//   to: To
// }

export default function Ama({
  post,
  detailView = false,
  ...rest
}: PostTypeProps) {
  const {
    title: { text },
    questionGeneratedThumbs,
    hlsDownloadLink,
  } = post
  const { jpgUrl } = questionGeneratedThumbs

  const bgElement = detailView ? (
    <ReactPlayer
      // light={<img src={answerImageUrl} alt={text} />}
      url={hlsDownloadLink}
      controls
      width="100%"
      height="100%"
    />
  ) : (
    <img alt={text} src={jpgUrl} />
  )

  return <Card bgCoverElement={bgElement} {...rest} />
}
