import Card from '../../Card'
import { PostTypeProps } from '../types'
import classes from './LockedPostCard.module.css'
import bgImg from './locked-bg.jpg'
import { ReactComponent as Lock } from '../../../../theme/icons/icon-lock.svg'
import classNames from 'classnames'

const cx = classNames.bind(classes)

export default function LockedPostCard({
  post,
  detailView = false,
  ...rest
}: PostTypeProps) {
  const {
    title: { text },
    description,
  } = post

  return (
    <Card noHover bgCoverElement={<img alt="text" src={bgImg} />} {...rest}>
      <div
        className={cx(classes.overlay, {
          [classes['overlay--detail']]: true,
        })}
      >
        <span
          className={cx(classes.title, {
            [classes['title--detail']]: detailView,
          })}
        >
          {detailView ? description : text}
        </span>
        {detailView ? (
          <div className={classes.details}>
            <div className={classes.details__primary}>
              <Lock /> Locked content
            </div>
            <div className={classes.details__secondary}>
              Download QP to discover more.
            </div>
          </div>
        ) : (
          <Lock className={classes.lock} />
        )}
      </div>
    </Card>
  )
}
