const styles = {
  styles: {
    global: {
      body: {
        lineHeight: '1.333333',
        color: 'var(--primary-500)',
      },
    },
  },
  fonts: {
    body: 'Rubik,"Segoe UI",Arial,sans-serif',
    heading: 'Rubik,"Segoe UI",Arial,sans-serif',
  },
  textStyles: {
    cardTitle: {
      fontSize: '17px',
      lineHeight: '22px',
      fontWeight: 400,
      marginBottom: 3,
      color: 'var(--chakra-colors-primary-950)',
    },
    cardSubtitle: {
      fontSize: '24px',
      lineHeight: '22px',
      fontWeight: 600,
      marginBottom: 3,
      color: 'var(--chakra-colors-primary-950)',
    },
    cardFeatureListItem: {
      fontSize: '13px',
      lineHeight: '18px',
      color: 'var(--chakra-colors-primary-500)',
      letterSpacing: '0.04em',
    },
    cardExtraFeatureListItem: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: '600',
      color: 'var(--chakra-colors-primary-950)',
      letterSpacing: '0.37px',
    },
  },
  colors: {
    primary: {
      500: '#71739A',
      600: '#575890',
      950: '#030353',
    },
    accentPink: {
      400: '#F9E7F4',
      600: '#C21390',
    },
    cardBorder: {
      default: '#e5e5e5',
      selected: '#0045FF',
    },
    boxBorder: {
      default: '#EDEEEF',
    },
    accentBlue: {
      400: '#7fa2ff',
      500: '#0045FF',
    },
    gray: { default: '#6a7383' },
  },
}

export default styles
