import { Box, Flex } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useParams, useSearchParams } from 'react-router-dom'
import { getConfig } from '../../config'
import { CenteredSpinner } from '../CenteredSpinner'
import PayNow from './PayNow'
import usePostPurchase from './usePostPurchase'

const stripeKey = getConfig().stripe.publishableKey
const stripePromise = loadStripe(stripeKey)

export interface LineItemProps {
  label: string
  value: number
  isMuted?: boolean
}

function LineItem({ label, value, isMuted = false }: LineItemProps) {
  const formatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  }).format(value)

  return (
    <Flex alignItems="center">
      <Box flexGrow={1} flexShrink={0} textAlign="right" py={1.5} px={4}>
        <Box
          fontWeight={isMuted ? 400 : 600}
          fontSize="sm"
          lineHeight={1.3}
          color={isMuted ? 'blackAlpha.600' : 'black'}
        >
          {label}
        </Box>
      </Box>
      <Box flexGrow={0} flexShrink={0} width={115} textAlign="right" px={4}>
        <Box fontWeight={600} fontSize="sm" lineHeight={1.3} color="black">
          {formatted}
        </Box>
      </Box>
    </Flex>
  )
}

export default function PostPaymentCheckout() {
  const { postId } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
    postId: string
  }>()
  const [searchParams] = useSearchParams()
  const paymentMethodId = searchParams.get('pm')
  const { data, isLoading } = usePostPurchase(postId, paymentMethodId)

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (!data) {
    return (
      <Box py={6} color="red.600">
        Something went wrong. Please contact support.
      </Box>
    )
  }

  const { stripeClientSecret, total, totalTax, tax, currency } = data

  return (
    <Box pt={6}>
      <LineItem label="Subtotal" value={total - totalTax} />

      {tax.map(({ country, taxPercentage, taxAmount, displayName }, index) => {
        return (
          <LineItem
            isMuted
            key={`${index}-${displayName}`}
            label={`${
              displayName?.toUpperCase() || 'Tax'
            } - ${country} (${taxPercentage}%)`}
            value={taxAmount}
          />
        )
      })}

      <LineItem label={`Total ($${currency.toUpperCase()})`} value={total} />

      <Elements
        options={{
          clientSecret: stripeClientSecret,
          appearance: {
            theme: 'stripe',
          },
        }}
        stripe={stripePromise}
      >
        <PayNow
          stripeClientSecret={stripeClientSecret}
          paymentMethodId={paymentMethodId}
          postId={postId}
        />
      </Elements>
    </Box>
  )
}
