// import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import useGetChannel from '../../../hooks/useGetChannel'
// import useGetChannelPosts from '../../../hooks/useGetChannelPosts'
import useGetPublicPost from '../../../hooks/useGetPublicPost'
import { PostCard } from '../../Cards'
import ShareBtn from '../../ShareBtn'
import AmaAuthor from './AmaAuthor'
import PostedBy from './PostedBy'
import classes from './PublicPostPage.module.css'
import { useQuery } from '../../../utils/useQuery'
import { getConfig } from '../../../config'

interface PublicPostPageProps {
  channelIdentifier: string
  postId: string
  channelOwnerName: string
}

export default function PublicPostPage({
  channelIdentifier,
  postId,
  channelOwnerName,
}: PublicPostPageProps) {
  // const { data } = useGetChannelPosts({
  //   profileId: channelOwnerName,
  //   channelId: channelIdentifier,
  // })

  const { data: publicPosts } = useGetPublicPost({
    profileId: channelOwnerName,
    channelId: channelIdentifier,
    postId,
  })

  const { data: channel } = useGetChannel({
    profileId: channelOwnerName,
    channelId: channelIdentifier,
  })

  const query = useQuery()

  // const currentPost = useMemo(() => {
  //   if (!data) {
  //     return null
  //   }

  //   return data.find((item) => {
  //     return item.id === postId
  //   })
  // }, [data, postId])

  if (!publicPosts) {
    return null
  }

  const { currentPost, related } = publicPosts

  // username is just fallback
  const channelName =
    channel?.selectedChannel?.name ?? currentPost.publisherUsername
  const { postContentType, mediaPostType, unfurlImageUrl } = currentPost
  const postType = mediaPostType === 'AMA' ? 'AMA' : postContentType
  const title = postType === 'AMA' ? channelName : currentPost.title.text

  const branchChannel = query.get('channel')
    ? 'channel=' + query.get('channel')
    : 'channel=' +
      (channelOwnerName ? channelOwnerName : '') +
      (channelIdentifier ? '/' + channelIdentifier : '')

  const branchCode = query.get('code') ? '&code=' + query.get('code') : ''
  const branchSource = query.get('s') ? '&s=' + query.get('s') : ''
  const branchReferrer = query.get('r') ? '&r=' + query.get('r') : ''

  const branchLink =
    getConfig().branch.branchLink +
    `?${branchChannel}${branchCode}${branchSource}${branchReferrer}`

  const isAMA = postType === 'AMA'

  return (
    <>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={unfurlImageUrl} />
        <meta
          property="og:description"
          content={currentPost.properties?.body}
        />
      </Helmet>
      <div className={classes.wrapper}>
        <div>
          <PostCard
            aspectRatio="9:16"
            as="div"
            post={currentPost}
            noHover
            detailView
          />
        </div>
        <div className={classes['post-details']}>
          <div className={classes['post-details__content']}>
            {postType === 'AMA' ? (
              <h3 className={classes['post-title--AMA']}>{title}</h3>
            ) : (
              <h5 className={classes['post-title']}>{title}</h5>
            )}

            <p className={classes['post-details__body']}>
              {currentPost.properties?.body}
            </p>

            {postType === 'AMA' ? (
              <AmaAuthor post={currentPost} />
            ) : (
              <PostedBy post={currentPost} />
            )}

            {!currentPost.locked && (
              <div className={classes.actions}>
                {isAMA && (
                  <a href={branchLink}>
                    <button className={`${classes.btn_round} btn`}>
                      Ask Me
                    </button>
                  </a>
                )}
                <ShareBtn>Share</ShareBtn>
              </div>
            )}
          </div>
          <p className={classes['section-heading']}>
            Latest posts from{' '}
            <Link
              className={classes.latest__cta}
              to={`/${channelOwnerName}/${channelIdentifier}`}
            >
              {channelName}
            </Link>
          </p>
          <div className={classes.latest}>
            {related.map((relatedPost) => {
              const { id, mediaPostType } = relatedPost
              const isCurrentPost = id === currentPost.id
              const aspectRatio = mediaPostType === 'AMA' ? '3:4' : '2:3'

              if (isCurrentPost) {
                return (
                  <PostCard
                    aspectRatio={aspectRatio}
                    isActive
                    as={'div'}
                    key={id}
                    post={relatedPost}
                  />
                )
              }

              return (
                <PostCard
                  aspectRatio={aspectRatio}
                  key={id}
                  post={relatedPost}
                  to={`/${channelOwnerName}/${channelIdentifier}/${id}`}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
