import ReactPlayer from 'react-player'
import Card from '../../Card'
import { PostTypeProps } from '../types'

// interface VideoProps {
//   post: components['schemas']['PublicWebPost']
//   to: To
// }

export default function Video({
  post,
  detailView = false,
  ...rest
}: PostTypeProps) {
  const {
    title: { text },
    generatedThumbs,
    hlsDownloadLink,
  } = post
  const { gifUrl } = generatedThumbs

  const bgElement = detailView ? (
    <ReactPlayer
      // light={<img src={jpgUrl} alt={text} />}
      url={hlsDownloadLink}
      controls
      width="100%"
      height="100%"
    />
  ) : (
    <img alt={text} src={gifUrl} />
  )

  return <Card className="video" bgCoverElement={bgElement} {...rest} />
}
