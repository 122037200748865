import { Image } from '@chakra-ui/react'
import classes from './PublicLayout.module.css'
import { Fragment, ReactElement } from 'react'
import { ReactComponent as Logo } from './../../../theme/icons/qp-logo.svg'
import { createPortal } from 'react-dom'

interface PublicLayoutProps {
  branchLink: string
  androidURL: string
  children?: ReactElement
}

export default function PublicLayout({
  branchLink,
  androidURL,
  children,
}: PublicLayoutProps) {
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          {children}
          <div className={classes.footer}>
            <div className={classes.footer__content}>
              <div className={classes.footer__brand}>
                <a href="https://www.qp.me">
                  <Logo />
                </a>
                <span>Discover more on the app.</span>
              </div>
              <div className={classes.footer__links}>
                <a href={branchLink}>
                  <Image
                    src="https://assets.qp.me/app-store.svg"
                    alt="Download on the App Store"
                    height="40px"
                  />
                </a>
                <a href={androidURL}>
                  <Image
                    src="https://assets.qp.me/google-play.svg"
                    alt="Get it on Google Play"
                    height="40px"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {createPortal(
        <>
          <input type="checkbox" className={classes.toggler} />
          <div className={classes.hamburger}>
            <div></div>
          </div>
          <div className={classes.menu}>
            <div>
              <ul>
                <li className={classes.menu__item}>
                  <a href="/">Home</a>
                </li>
                <li className={classes.menu__item}>
                  <a href="/features">Features</a>
                </li>
                <li className={classes.menu__item}>
                  <a href="https://help.qp.me" target="_blank" rel="noreferrer">
                    Support
                  </a>
                </li>
                <li className={classes.menu__item}>
                  <a href="/about">About QP</a>
                </li>
                <li className={classes['menu__item-sm']}>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li className={classes['menu__item-sm']}>
                  <a href="/terms-of-service">Terms of Service</a>
                </li>
              </ul>
            </div>
          </div>
        </>,
        document.body
      )}
    </>
  )
}
