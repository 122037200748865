import { useQuery } from 'react-query'
import { paths } from '../api-types/channels-api-types'
import { webGatewayApi } from '../services/base'

type GetChannelResponseData =
  paths['/channels/pub/profile/{profile}/{identifier}']['get']['responses']['200']['content']['application/json']

interface UseGetChannelParams {
  profileId: string
  channelId: string
}

export default function useGetChannel({
  profileId,
  channelId,
}: UseGetChannelParams) {
  return useQuery(['public-channel', profileId, channelId], async () => {
    const { data, errors } = await webGatewayApi.get<GetChannelResponseData>(
      `/channels/pub/profile/${profileId}/${channelId}`
    )

    if (errors.length > 0) {
      throw new Error(errors[0].message)
    }

    return data
  })
}
