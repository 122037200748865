import * as React from 'react'

export type ProfilePictureProps = {
  channelPictureSrc: string
  channelPictureAlt: string
  profilePictureSrc: string
  profileTitle: string
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  channelPictureSrc,
  channelPictureAlt,
  profilePictureSrc,
  profileTitle,
}) => {
  return (
    <div
      className="header"
      style={{
        backgroundImage: `linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${channelPictureSrc})`,
      }}
    >
      <div className="owner">
        <div className="avatar">
          <img
            src={profilePictureSrc}
            alt={channelPictureAlt}
            className="avatar__img"
          />
        </div>
        <div className="owner-text">
          {profileTitle}
          <span className="fade-text"> (owner)</span>
        </div>
      </div>
    </div>
  )
}

export { ProfilePicture }
