import React, { useEffect, useMemo, useState } from 'react'
import {
  getChannelByIdentifier,
  GetChannelByIdentifierResponseData,
} from '../../services/channels/getChannelByIdentifier'
import { getProfilePicture } from '../../services/channels/getProfilePicture'

type ContextState = {
  loading: boolean
  channelIdentifier: string
  channelOwnerName: string
  channel?: GetChannelByIdentifierResponseData
  profileImage?: string
  hasErrors: boolean
}

const Context = React.createContext<ContextState | null>(null)

export const useChannel = (): ContextState => {
  const contextState = React.useContext(Context)
  if (contextState === null) {
    throw new Error('useChannel must be used within a ChannelProvider tag')
  }
  return contextState
}

const ChannelProvider: React.FC<{
  channelIdentifier: string
  channelOwnerName: string
}> = (props) => {
  const { channelIdentifier, channelOwnerName } = props
  const initialState = useMemo(
    () => ({
      loading: true,
      channelIdentifier,
      channelOwnerName,
      value: undefined,
      hasErrors: false,
    }),
    [channelIdentifier, channelOwnerName]
  )
  const [contextValue, setContextValue] = useState<ContextState>(initialState)

  useEffect(() => {
    ;(async (): Promise<void> => {
      setContextValue(initialState)
      const { data: channel, errors } = await getChannelByIdentifier({
        channelIdentifier,
        channelOwnerName,
      })

      const profileImage = await getProfilePicture({
        accountId: channel.accountId,
        profileId: channel.profileId,
      })
      setContextValue((prevState) => ({
        ...prevState,
        loading: false,
        channel,
        profileImage: profileImage.data.pictureDownloadLink,
        hasErrors: errors.length > 0,
      }))
    })()
  }, [channelIdentifier, channelOwnerName, initialState])

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  )
}

export { ChannelProvider }
