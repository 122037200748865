import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import useGetChannel from '../../hooks/useGetChannel'
import { useAuth } from '../../providers/AuthProvider'
import { CenteredSpinner } from '../CenteredSpinner'
import useGetAmaPurchaseDetails from './useGetAmaPurchaseDetails'

interface AmaPurchaseTemplateProps {
  children?: ReactElement
}

export default function AmaPurchaseTemplate({
  children,
}: AmaPurchaseTemplateProps) {
  const { signedIn } = useAuth()
  const [searchParams] = useSearchParams()
  const { channelOwnerName, channelIdentifier } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
  }>()
  const questionId = searchParams.get('questionId')

  const { data: purchaseDetails, isLoading: detailsLoading } =
    useGetAmaPurchaseDetails(questionId)

  const { data, isLoading } = useGetChannel({
    profileId: channelOwnerName,
    channelId: channelIdentifier,
  })

  if (isLoading || detailsLoading || !signedIn) {
    return <CenteredSpinner />
  }

  if (!data) {
    return null
  }

  const {
    selectedChannel: { name },
  } = data

  return (
    <>
      <Box pt={4}>
        <Heading
          as="h2"
          fontSize={22}
          lineHeight={1.3}
          fontWeight={600}
          color={'primary.950'}
          pb={2}
        >
          {name}
        </Heading>
        <Flex
          alignItems="center"
          flexDirection="row"
          gap="8px"
          p="8px"
          py="4px"
          borderRadius="8px"
          backgroundColor="#E6ECFF"
        >
          <Box>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 3.83398C7.02944 3.83398 3 7.86342 3 12.834C3 17.8045 7.02944 21.834 12 21.834C16.9706 21.834 21 17.8045 21 12.834C21 7.86342 16.9706 3.83398 12 3.83398Z"
                fill="#0045FF"
                stroke="#0045FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 17.334V12.084"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 7.58398C11.3787 7.58398 10.875 8.08766 10.875 8.70898C10.875 9.3303 11.3787 9.83398 12 9.83398C12.6213 9.83398 13.125 9.3303 13.125 8.70898C13.125 8.08766 12.6213 7.58398 12 7.58398Z"
                fill="white"
              />
            </svg>
          </Box>
          <Box
            fontSize={12}
            fontWeight={400}
            lineHeight="16px"
            color={'primary.950'}
          >
            You will not be charged at this time
          </Box>
        </Flex>

        <Text
          mt="32px"
          fontSize={17}
          lineHeight={1.2}
          fontWeight={600}
          color={'primary.950'}
        >
          ${purchaseDetails.total} Purchase
        </Text>
      </Box>
      {children}
    </>
  )
}
