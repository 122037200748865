import React from 'react'
import { IconProps } from '@chakra-ui/react'
import { components as ChannelsApiComponents } from '../../api-types/channels-api-types'
import {
  getPriceDescription,
  getPriceRecurrence,
} from '../../utils/PriceOptionUtils'

export interface SubscriptionAddOnsListProps {
  listItemTextStyle: string
  listItemIcon: React.FunctionComponent<IconProps>
  children: ChannelsApiComponents['schemas']['SubscriptionAddOn'][]
  mb?: number
  flexGrow?: number
}
export interface PromoOptionsListProps {
  listItemTextStyle: string
  listItemIcon: React.FunctionComponent<IconProps>
  children: ChannelsApiComponents['schemas']['PromoOption'][]
  mb?: number
  flexGrow?: number
}

export interface SubscriptionOptionCardProps {
  name: string
  description: string
  previousTier: string
  priceOption: ChannelsApiComponents['schemas']['SubscriptionPriceOption']
  subscriptionAddOns: ChannelsApiComponents['schemas']['SubscriptionAddOn'][]
  promoOptions?: ChannelsApiComponents['schemas']['PromoOption'][]
  selected?: boolean
  value?: string
  showRadio?: boolean
  onClick: (value: string, promo: string, free: boolean) => void
  status: boolean
  withMessaging?: boolean
}

const SubscriptionOptionCard = ({
  name,
  description,
  priceOption,
  promoOptions,
  selected = false,
  value,
  onClick,
  showRadio = false,
  status,
  withMessaging,
}: SubscriptionOptionCardProps): JSX.Element => {
  const handleClick = () => {
    onClick(
      value,
      promoOptions ? promoOptions[0]?.id : '',
      priceOption.price === 0
    )
  }

  const handleFullClick = () => {
    // Should add a toast/modal
  }

  return (
    <div>
      <div
        className={'subs ' + (status ? '' : 'full')}
        onClick={status ? handleClick : handleFullClick}
      >
        {!status ? (
          <div className="ribbon">
            At
            <br />
            Capacity
          </div>
        ) : (
          ''
        )}
        <div className="subs-title">
          {withMessaging ? 'Exclusive + Messaging' : 'Exclusive'}
        </div>

        {promoOptions ? (
          <div>
            <div className="discount">
              <span className="price-orig">${priceOption.price}</span>

              <span className="disc">
                {getPriceDescription(
                  priceOption,
                  promoOptions ? promoOptions[0]?.percentDiscount : 0
                )}
              </span>
              <span className="duration">
                {getPriceRecurrence(
                  priceOption,
                  promoOptions ? promoOptions[0]?.percentDiscount : 0
                )}
              </span>
            </div>
            <div className="feature">
              <div className="terms">
                {`${promoOptions[0]?.percentDiscount}% off`}{' '}
                {promoOptions[0]?.duration === 'ONCE'
                  ? 'first ' +
                    (priceOption.recurrenceIntervalCount > 1
                      ? priceOption.recurrenceIntervalCount + ' '
                      : '') +
                    priceOption.recurrence +
                    (priceOption.recurrenceIntervalCount > 1 ? 's' : '')
                  : ''}
              </div>
              {promoOptions[0]?.limit?.endDate ? (
                <div className="expiry">
                  Offer expires
                  {` ` +
                    new Date(promoOptions[0].limit.endDate).toLocaleDateString(
                      undefined,
                      { dateStyle: 'medium' }
                    )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <div className="discount">
            <span className="price">${priceOption.price}</span>
            <span className="duration">
              {getPriceRecurrence(
                priceOption,
                promoOptions ? promoOptions[0]?.percentDiscount : 0
              )}
            </span>
          </div>
        )}
        <div className="desc">{description}</div>
      </div>
    </div>
  )
}

export { SubscriptionOptionCard }
