const Table = {
  variants: {
    simple: {
      tbody: {
        tr: {
          textAlign: 'right',
          borderStyle: 'hidden',
        },
        td: {
          textAlign: 'right',
        },
      },
    },
  },
}

export default Table
