import { components } from '../../../api-types/channels-api-types'
import useGetChannelPosts from '../../../hooks/useGetChannelPosts'
import { PostCard } from '../../Cards'
import classes from './AmaRow.module.css'

interface AmaRowProps {
  channel: components['schemas']['PublicWebChannel']
  channelOwnerName: string
}

export default function AmaRow({ channel, channelOwnerName }: AmaRowProps) {
  const { identifier } = channel

  const { data } = useGetChannelPosts({
    profileId: channelOwnerName,
    channelId: identifier,
  })

  if (!data) {
    // TODO: fallback state
    return null
  }

  return (
    <div className={classes.row}>
      {data.map((post) => {
        const { id } = post

        return (
          <PostCard
            aspectRatio="3:4"
            key={id}
            post={post}
            to={`/${channelOwnerName}/${identifier}/${id}`}
          />
        )
      })}
    </div>
  )
}
