import { paths } from '../../api-types/channels-api-types'
import { webGatewayApi } from '../base'

type GetChannelByIdentifierRequestParams =
  paths['/channels/{channelOwnerName}/{channelIdentifier}']['get']['parameters']['path']
export type GetChannelByIdentifierResponseData =
  paths['/channels/{channelOwnerName}/{channelIdentifier}']['get']['responses']['200']['content']['application/json']

export const getChannelByIdentifier = async ({
  channelIdentifier,
  channelOwnerName,
}: GetChannelByIdentifierRequestParams) => {
  const response = await webGatewayApi.get<GetChannelByIdentifierResponseData>(
    `/channels/${channelOwnerName}/${channelIdentifier}`
  )

  return response
}
