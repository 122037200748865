/** @jsxImportSource @emotion/react */
import { Box } from '@chakra-ui/react'

export type PostItemProps = {
  imageSrc: string
  imageAlt: string
}

const PROFILE_PICTURE_DIMENSION = 343

// Remove the smalll white space after the img, created by the inline-block style
// const container = css`
//   > span {
//     display: block !important;
//   }
// `

// Width and Height are currently hardcoded 151,151
// Going forward we need to control the dimensions of the profile background picture server side
const PostItem: React.FC<PostItemProps> = ({ imageSrc, imageAlt }) => {
  return (
    <>
      <Box>
        {/* <Box css={container}> */}
        <img
          width={PROFILE_PICTURE_DIMENSION}
          height={PROFILE_PICTURE_DIMENSION}
          src={imageSrc}
          alt={imageAlt}
        />
      </Box>
    </>
  )
}

export { PostItem }
