import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { CenteredSpinner } from '../CenteredSpinner'
import useGetAmaPurchaseTaxes from './useGetAmaPurchaseTaxes'
import usePostAmaPurchase from './usePostAmaPurchase'

export interface LineItemProps {
  label: string
  value: number
  isMuted?: boolean
}

function LineItem({ label, value, isMuted = false }: LineItemProps) {
  const formatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  }).format(value)

  return (
    <Flex alignItems="center">
      <Box flexGrow={1} flexShrink={0} textAlign="right" py="4px" px={4}>
        <Box
          fontWeight={500}
          fontSize="14px"
          lineHeight="18px"
          color={isMuted ? 'blackAlpha.600' : 'black'}
        >
          {label}
        </Box>
      </Box>
      <Box
        flexGrow={0}
        flexShrink={0}
        width={115}
        textAlign="right"
        py="4px"
        px={4}
      >
        <Box fontWeight={500} fontSize="14px" lineHeight="18px" color="black">
          {formatted}
        </Box>
      </Box>
    </Flex>
  )
}

export default function AmaPurchaseCheckout() {
  const [isError, setIsError] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { channelOwnerName, channelIdentifier } = useParams<{
    channelOwnerName: string
    channelIdentifier: string
  }>()
  const paymentMethodId = searchParams.get('pm')
  const questionId = searchParams.get('questionId')
  const postId = searchParams.get('postId')

  const { data, isLoading, mutate } = useGetAmaPurchaseTaxes()
  const { mutateAsync: createAmaPurchase, isLoading: purchaseLoading } =
    usePostAmaPurchase()

  const handlPurchase = async () => {
    try {
      await createAmaPurchase({ paymentMethodId, questionId })
      navigate(`/${channelOwnerName}/${channelIdentifier}/${postId}/success`)
    } catch (error) {
      setIsError(true)
    }
  }

  useEffect(() => {
    mutate({ paymentMethodId, questionId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (!data || isError) {
    return (
      <Box py={6} color="red.600">
        Something went wrong. Please contact support.
      </Box>
    )
  }

  const { subTotal, tax, currency, total } = data

  return (
    <>
      <Text
        mt="32px"
        fontSize={18}
        lineHeight={1.2}
        fontWeight={500}
        color={'primary.950'}
      >
        Review fees
      </Text>

      <LineItem label="Subtotal" value={subTotal} />

      {tax.map(({ country, taxPercentage, taxAmount, displayName }, index) => {
        return (
          <LineItem
            isMuted
            key={`${index}-${displayName}`}
            label={`${
              displayName?.toUpperCase() || 'Tax'
            } - ${country} (${taxPercentage}%)`}
            value={taxAmount}
          />
        )
      })}

      <Divider my="4px" borderColor="#D2D3D9" />

      <LineItem label={`Total ($${currency.toUpperCase()})`} value={total} />

      <Text
        fontWeight={400}
        fontSize={12}
        lineHeight="17px"
        color={'primary.500'}
        mt="24px"
      >
        No charges will be applied to your credit card until the creator answers
        your question. If the creator declines to answer, or if the question is
        unanswered after 30 days, the question will expire and no charges will
        be processed.
      </Text>
      <Text
        fontWeight={400}
        fontSize={12}
        lineHeight="17px"
        color={'primary.500'}
      >
        Additionally, if you withdraw your question before the creator has
        answered it, no charges will be incurred.
      </Text>

      <Button
        mt={4}
        disabled={purchaseLoading}
        id="submit"
        type="button"
        variant="primary"
        onClick={(e) => {
          e.stopPropagation()
          handlPurchase()
        }}
      >
        {purchaseLoading ? <CenteredSpinner /> : 'Post question'}
      </Button>
    </>
  )
}
