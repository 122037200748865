import { ReactNode } from 'react'
import { useAccessTokenQueryParamToAuthenticate } from '../components/Authentication'
import { CenteredSpinner } from '../components/CenteredSpinner'

interface AuthHydrationProps {
  children?: ReactNode
}

export default function AuthHydration({ children }: AuthHydrationProps) {
  const { isLoading } = useAccessTokenQueryParamToAuthenticate()

  if (isLoading) {
    return <CenteredSpinner />
  }

  return <>{children}</>
}
