import { Box, Button } from '@chakra-ui/react'
import { Heading } from '../Heading'
import { CreatePaymentMethod } from './CreatePaymentMethod'
import { SelectPaymentMethods } from './SelectPaymentMethod'
import { usePaymentMethods } from './usePaymentMethods'
import { useNavigateToPaymentReview } from './useNavigateToPaymentReview'
import { CenteredSpinner } from '../CenteredSpinner'
import { useSearchParams } from 'react-router-dom'

function SubscriptionPaymentMethods(): JSX.Element {
  const {
    loading: loadingPaymentMethods,
    paymentMethods,
    selectedPaymentMethod,
    selectedIsNew,
    setSelectedPaymentMethod,
    hasError,
  } = usePaymentMethods()
  const [searchParams] = useSearchParams()
  const subscriptionOptionIdQueryParam = searchParams.get('subscriptionId')
  const subscriberProfileId = searchParams.get('subscriberProfileId')
  const promoIdQueryParam = searchParams.get('promoId')

  const { navigateToPaymentReview } = useNavigateToPaymentReview(
    selectedPaymentMethod
  )

  return (
    <Box
      maxWidth={450}
      margin="0 auto"
      p={4}
      pb={10}
      borderTopWidth={1}
      borderTopColor={'boxBorder.default'}
    >
      <Heading type="h2">Payment Method</Heading>
      {loadingPaymentMethods && <CenteredSpinner />}
      {hasError && <p>An error occured while loading your payment methods.</p>}
      {!hasError && !loadingPaymentMethods && (
        <SelectPaymentMethods
          value={selectedPaymentMethod}
          isDisabled={paymentMethods.length < 2}
          paymentMethods={paymentMethods}
          onChange={setSelectedPaymentMethod}
        />
      )}
      {!hasError &&
        !loadingPaymentMethods &&
        selectedPaymentMethod &&
        !selectedIsNew && (
          <Button
            mt={4}
            disabled={!selectedPaymentMethod}
            type="submit"
            onClick={navigateToPaymentReview}
            variant="primary"
          >
            {'Proceed to Checkout'}
          </Button>
        )}
      {!hasError && !loadingPaymentMethods && selectedIsNew && (
        <CreatePaymentMethod
          checkoutParams={{
            subscriptionId: subscriptionOptionIdQueryParam,
            promoId: promoIdQueryParam,
            subscriberProfileId: subscriberProfileId,
          }}
        />
      )}
    </Box>
  )
}

export { SubscriptionPaymentMethods }
