import { useCallback } from 'react'
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom'
import {
  AnonymousOnlyOutlet,
  PrivateOutlet,
  useAccessTokenQueryParamToAuthenticate,
} from '../../components/Authentication'
import { ChannelHeader } from '../../components/ChannelHeader'
import {
  ChannelSubscriptionOptions,
  ChannelSubscriptionOptionsProps,
} from '../../components/ChannelSubscriptionOptions'
import { EmbeddedLoginForm } from '../../components/EmbeddedLoginForm'
import { NewPaymentMethodStatus } from '../../components/SubscriptionPayment'
import {
  SubscriptionFreeSuccess,
  SubscriptionPaymentCheckout,
  SubscriptionPaymentSuccess,
} from '../../components/SubscriptionPayment/SubscriptionPaymentCheckout'
import { SubscriptionPaymentMethods } from '../../components/SubscriptionPayment/SubscriptionPaymentMethods'
import { useChannel } from '../../providers/ChannelProvider'
import { Helmet } from 'react-helmet-async'
import { CenteredSpinner } from '../../components/CenteredSpinner'
import { Container } from '@chakra-ui/react'
import { useQuery } from '../../utils/useQuery'
import { getConfig } from '../../config'
import PostPage from '../../components/PostPage'
import PostPaymentCheckout from '../../components/PostPurchase/PostPaymentCheckout'
import PostPurchaseTemplate from '../../components/PostPurchase/PostPurchaseTemplate'
import PublicLayout from '../../components/layout/PublicLayout'
import PublicChannelPage from '../../components/PublicChannelPage'
import AmaPurchase, { AmaPurchaseCheckout } from '../../components/AmaPurchase'
import AmaPurchaseTemplate from '../../components/AmaPurchase/AmaPurchaseTemplate'
import AuthHydration from '../../AuthHydration'
import AmaPurchaseSuccess from '../../components/AmaPurchase/AmaPurchaseSuccess'

function ChannelSubscriptionOptionsTemplate() {
  const { loading, hasErrors, channel, profileImage } = useChannel()

  if (hasErrors) {
    return <>Error</>
  }

  if (loading) {
    return <CenteredSpinner />
  }

  return (
    <>
      <ChannelHeader
        channelPictureSrc={channel.properties?.imageDownloadLink}
        channelPictureAlt={`${channel.identifier} channel picture`}
        profilePictureSrc={profileImage}
        profileTitle={channel.ownerUserName}
        channelTitle={channel.name}
        profileDescription={channel.description}
      />
      <Outlet />
    </>
  )
}

const ChannelSubscriptionOptionsWithChannelSubscriptionOptions = (
  props: ChannelSubscriptionOptionsProps
) => {
  const { loading, hasErrors, channel, profileImage } = useChannel()
  useAccessTokenQueryParamToAuthenticate()
  // Make the auth check very rudimentary for now. Should be checking auth token is actually valid.
  const query = useQuery()
  const signedIn = query.get('token')
  const branchChannel = channel
    ? `${channel.ownerUserName}/${channel.identifier}`.toLowerCase()
    : ''
  const branchCode = query.get('code') ? '&code=' + query.get('code') : ''
  const branchSource = query.get('s') ? '&s=' + query.get('s') : ''
  const branchReferrer = query.get('r') ? '&r=' + query.get('r') : ''

  const branchLink =
    getConfig().branch.branchLink +
    `?channel=${branchChannel}${branchCode}${branchSource}${branchReferrer}`

  if (hasErrors) {
    return <>Error</>
  }

  if (loading) {
    return <CenteredSpinner />
  }

  return (
    <>
      <Helmet>
        <title>
          {channel?.name} | QP | Premium Messaging &amp; Monetization App for
          Content Creators
        </title>
      </Helmet>
      {signedIn ? (
        <Container className="page" m={0}>
          <>
            <ChannelHeader
              channelPictureSrc={channel.properties?.imageDownloadLink}
              channelPictureAlt={`${channel.identifier} channel picture`}
              profilePictureSrc={profileImage}
              profileTitle={channel.ownerUserName}
              channelTitle={channel.name}
              profileDescription={channel.description}
            />
            <ChannelSubscriptionOptions
              subscriptionOptions={channel.subscriptionOptions}
              {...props}
            />
          </>
        </Container>
      ) : (
        <PublicLayout branchLink={branchLink} androidURL={branchLink}>
          <PublicChannelPage />
        </PublicLayout>
      )}
    </>
  )
}

const ChannelSubscriptionOptionsContainer = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const subscriberProfileId = query.get('subscriberProfileId')

  const handleSubscriptionOptionChange = useCallback(
    (subscriptionId: string, promoId: string, isFree: boolean) => {
      navigate(
        isFree
          ? `./free-success?subscriptionId=${subscriptionId}`
          : `./payment-method?subscriptionId=${subscriptionId}&promoId=${promoId}&subscriberProfileId=${subscriberProfileId}`
      )
    },
    [navigate, subscriberProfileId]
  )

  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <ChannelSubscriptionOptionsWithChannelSubscriptionOptions
              onChange={handleSubscriptionOptionChange}
            />
          }
        />

        <Route path="/ama-purchase">
          <Route
            element={
              <AuthHydration>
                <Container className="page" m={0}>
                  <AmaPurchaseTemplate>
                    <PrivateOutlet />
                  </AmaPurchaseTemplate>
                </Container>
              </AuthHydration>
            }
          >
            <Route index element={<AmaPurchase />} />
            <Route path="purchase" element={<AmaPurchaseCheckout />} />
          </Route>

          <Route path="success" element={<AmaPurchaseSuccess />} />
        </Route>

        <Route
          element={
            <Container className="page" m={0}>
              <ChannelSubscriptionOptionsTemplate />
            </Container>
          }
        >
          <Route path="payment-method" element={<PrivateOutlet />}>
            <Route path="" element={<SubscriptionPaymentMethods />} />
          </Route>
          <Route path="new-payment-method-status" element={<PrivateOutlet />}>
            <Route path="" element={<NewPaymentMethodStatus />} />
          </Route>
          <Route path="payment-review" element={<PrivateOutlet />}>
            <Route path="" element={<SubscriptionPaymentCheckout />} />
          </Route>
          <Route path="payment-success" element={<PrivateOutlet />}>
            <Route path="" element={<SubscriptionPaymentSuccess />} />
          </Route>
          <Route path="free-success" element={<PrivateOutlet />}>
            <Route path="" element={<SubscriptionFreeSuccess />} />
          </Route>
          <Route path="login" element={<AnonymousOnlyOutlet />}>
            <Route path="" element={<EmbeddedLoginForm />} />
          </Route>
        </Route>
      </Route>
      <Route path="/:postId/">
        <Route index element={<PostPage />} />
        <Route
          path="purchase"
          element={
            <PostPurchaseTemplate>
              <PostPaymentCheckout />
            </PostPurchaseTemplate>
          }
        />
      </Route>
    </Routes>
  )
}

export { ChannelSubscriptionOptionsContainer }
