import { useContext } from 'react'
import {
  useOutletContext,
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom'
import { AuthContext } from '../../providers/AuthProvider'

export function AnonymousOnlyOutlet() {
  const { signedIn } = useContext(AuthContext)
  const outletContextValue = useOutletContext()
  const location = useLocation()

  return signedIn ? (
    <Navigate to={(location?.state as { from: string })?.from ?? '/'} replace />
  ) : (
    <Outlet context={outletContextValue} />
  )
}
