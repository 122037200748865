import { ChannelProvider } from '../providers/ChannelProvider'
import { useParams } from 'react-router-dom'
import { ChannelSubscriptionOptionsContainer } from '../containers/ChannelSubscriptionOptionsContainer'

const ChannelSubscriptionOptionsPage = () => {
  const { channelOwnerName, channelIdentifier } =
    useParams<{ channelOwnerName: string; channelIdentifier: string }>()

  return (
    channelOwnerName &&
    channelIdentifier && (
      <ChannelProvider {...{ channelOwnerName, channelIdentifier }}>
        <ChannelSubscriptionOptionsContainer />
        {/* <ChannelPage
        {...{
          profilePicture: PROFILE_PICTURE,
          profileTitle: 'Fitness by Jamie',
          profileDescription:
            'Love for all things beauty and fashion. Focused on a journey towards inner growth throughself-care and living a healthy lifestyle. Inspiring others to find their path to physical and spiritual wellness through my passion for Yoga.',
          offers: OFFERS,
          posts: POSTS,
        }}
      /> */}
      </ChannelProvider>
    )
  )
}
export default ChannelSubscriptionOptionsPage
