import { Box, Stack, Text, Image, Link } from '@chakra-ui/react'
import { Heading } from '../Heading'
//import { isIOS, isAndroid } from 'react-device-detect'
import { useQuery } from '../../utils/useQuery'
import { getConfig } from '../../config'

const ConnectPage = () => {
  const query = useQuery()
  const branchChannel = query.get('channel')
    ? 'channel=' + query.get('channel')
    : ''
  const branchCode = query.get('code') ? '&code=' + query.get('code') : ''
  const branchSource = query.get('s') ? '&s=' + query.get('s') : ''
  const branchReferrer = query.get('r') ? '&r=' + query.get('r') : ''

  const branchLink =
    getConfig().branch.branchLink +
    `?${branchChannel}${branchCode}${branchSource}${branchReferrer}`

  const playStoreUp = true
  const androidURL = playStoreUp ? branchLink : '/android'

  // async function copyToClipboard() {
  //   if (isIOS || isAndroid) {
  //     //await navigator.clipboard.writeText(branchLink)
  //     window.location.replace(branchLink)
  //   }
  // }

  // copyToClipboard()

  return (
    <Box height={'100vh'}>
      <Box
        height={'400px'}
        backgroundImage={
          'https://s3.us-west-2.amazonaws.com/prod.qp.me/images/welcome.jpg'
        }
        backgroundSize={'contain'}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center center'}
        backgroundColor={'#fff'}
      ></Box>
      <Box p={4} backgroundColor={'#fff'}>
        <Heading type="h1" mb={3} textAlign="center">
          We're changing the social media game.
        </Heading>

        <Text
          textAlign="center"
          fontSize="lg"
          lineHeight="24px"
          maxWidth={'560px'}
          margin={'0 auto'}
        >
          QP is a messenger-first social media platform designed to empower
          everyone to chat with friends, share content and monetize talents.
          Download QP on the App Store and on Google Play.
        </Text>

        <Stack direction="row" align="center" justifyContent="center" mt="6">
          <Link href={branchLink}>
            <Image
              src="https://assets.qp.me/app-store.svg"
              alt="Download on the App Store"
              height="50px"
            />
          </Link>
          <Link href={androidURL}>
            <Image
              src="https://assets.qp.me/google-play.svg"
              alt="Get it on Google Play"
              height="50px"
            />
          </Link>
        </Stack>
      </Box>
    </Box>
  )
}

export { ConnectPage }
