import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Table,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { webGatewayApi } from '../../services/base'
import { useQuery } from '../../utils/useQuery'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { Appearance, loadStripe } from '@stripe/stripe-js'
import { getConfig } from '../../config'
import SubscriptionOptionFeatureCheckIcon from '../../theme/icons/offerFeatureCheck'
import { useChannel } from '../../providers/ChannelProvider'
import { useNavigate } from 'react-router-dom'
import { CenteredSpinner } from '../CenteredSpinner'

const stripeKey = getConfig().stripe.publishableKey
const stripePromise = loadStripe(stripeKey)

const createSubscriptionResponseContainsRequiredData = (
  resultData: CreateSubscriptionResponseData
) =>
  resultData?.stripeClientSecret &&
  resultData?.currency &&
  resultData?.total &&
  resultData?.status

interface CreateSubscriptionResponseData {
  currency: string
  subTotal: number
  tax: {
    country: string
    displayName: string
    jurisdiction: string
    taxAmount: number
    taxId: string
    taxPercentage: number
  }[]
  total: number
  totalTax: number
  stripeSubscriptionId: string
  stripeClientSecret: string
  status: string
}

const useCreateSubscriptionWithSelectedPaymentMethod = ({
  channelId,
  selectedPaymentMethodId,
}) => {
  const query = useQuery()
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState('')
  const subscriptionOptionIdQueryParam = query.get('subscriptionId')
  const subscriberProfileId = query.get('subscriberProfileId')
  const promoIdQueryParam = query.get('promoId')

  const [result, setResult] = useState<CreateSubscriptionResponseData>()

  useEffect(() => {
    ;(async () => {
      if (
        !selectedPaymentMethodId ||
        !channelId ||
        !subscriptionOptionIdQueryParam
      )
        return

      setLoading(true)
      const result = await webGatewayApi.post<CreateSubscriptionResponseData>(
        '/payments/subscription/create',
        {
          data: {
            channelId,
            subscriptionOptionId: subscriptionOptionIdQueryParam,
            promoId: promoIdQueryParam,
            paymentMethodId: selectedPaymentMethodId,
            subscriberProfileId: subscriberProfileId,
          },
        }
      )

      if (
        !createSubscriptionResponseContainsRequiredData(result.data) ||
        result.errors.length > 0
      ) {
        console.error(...result.errors)
        setHasError(result.errors[0].message)
      }

      if (createSubscriptionResponseContainsRequiredData(result.data)) {
        setResult(result.data)
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethodId, channelId, subscriptionOptionIdQueryParam])

  return { loading, subscriptionResult: result, hasError }
}

const SubscriptionInfo = ({ stripeClientSecret }) => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const query = useQuery()
  const subscriptionOptionIdQueryParam = query.get('subscriptionId')
  const subscriberProfileId = query.get('subscriberProfileId')
  const promoIdQueryParam = query.get('promoId')
  const { channelIdentifier, channelOwnerName } = useChannel()

  const [status, setStatus] = useState<{ message: string; code: string }>({
    message: null,
    code: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const paymentMethodIdQueryParam = query.get('pm')

    if (!stripe) {
      return
    }

    setIsLoading(true)

    const { paymentIntent, error } = await stripe.confirmCardPayment(
      stripeClientSecret,
      {
        payment_method: paymentMethodIdQueryParam,
        return_url: `${window.location.origin}/${channelOwnerName}/${channelIdentifier}/payment-success?subscriptionId=${subscriptionOptionIdQueryParam}&subscriberProfileId=${subscriberProfileId}`,
      }
    )

    switch (paymentIntent?.status) {
      case 'succeeded':
        setStatus({
          message: 'Payment succeeded!',
          code: paymentIntent.status,
        })
        setIsLoading(false)
        navigate(
          `/${channelOwnerName}/${channelIdentifier}/payment-success?subscriptionId=${subscriptionOptionIdQueryParam}&promoId=${promoIdQueryParam}&subscriberProfileId=${subscriberProfileId}`
        )
        break
      default:
        setStatus({
          message: error.message || 'Something went wrong.',
          code: paymentIntent?.status,
        })
        break
    }

    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isInvalid={status.code !== 'succeeded'} id="payment-form">
        {status.code === 'succeeded' && (
          <>
            <SubscriptionOptionFeatureCheckIcon boxSize={10} />
            {status.message}
          </>
        )}
        {(status.code !== 'succeeded' || isLoading) && (
          <Button
            mt={4}
            disabled={isLoading || !stripe || !elements}
            id="submit"
            type="submit"
            variant="primary"
          >
            {isLoading ? <CenteredSpinner /> : 'Pay Now'}
          </Button>
        )}
        <FormErrorMessage>{status.message}</FormErrorMessage>
      </FormControl>
    </form>
  )
}

const SubscriptionPaymentCheckout = (): JSX.Element => {
  const query = useQuery()
  const { channel } = useChannel()
  const paymentMethodIdQueryParam = query.get('pm')

  const { loading, subscriptionResult, hasError } =
    useCreateSubscriptionWithSelectedPaymentMethod({
      channelId: channel.id,
      selectedPaymentMethodId: paymentMethodIdQueryParam,
    })

  const appearance: Appearance = {
    theme: 'stripe',
  }

  return (
    <Box
      maxWidth={450}
      margin="0 auto"
      p={4}
      borderTopWidth={1}
      borderTopColor={'boxBorder.default'}
    >
      {hasError && <p>{hasError}</p>}
      {!hasError && !loading && subscriptionResult && (
        <>
          <Table variant="simple" size="sm">
            <Tbody>
              <Tr style={{ fontWeight: 'bold' }}>
                <Td>Subtotal</Td>
                <Td isNumeric>${subscriptionResult.subTotal.toFixed(2)}</Td>
              </Tr>
              {subscriptionResult.total <
                subscriptionResult.subTotal + subscriptionResult.totalTax && (
                <Tr>
                  <Td color="gray">Discount</Td>
                  <Td isNumeric style={{ fontWeight: 'bold' }}>
                    -$
                    {(
                      subscriptionResult.subTotal +
                      subscriptionResult.totalTax -
                      subscriptionResult.total
                    ).toFixed(2)}
                  </Td>
                </Tr>
              )}
              {subscriptionResult.tax.map((taxItem) => (
                <Tr>
                  <Td color="gray">
                    {taxItem.displayName} - {taxItem.jurisdiction} (
                    {taxItem.taxPercentage}%)
                  </Td>
                  <Td isNumeric style={{ fontWeight: 'bold' }}>
                    ${taxItem.taxAmount.toFixed(2)}
                  </Td>
                </Tr>
              ))}
              <Tr style={{ fontWeight: 'bold' }}>
                <Td>Total ($USD)</Td>
                <Td isNumeric>${subscriptionResult.total.toFixed(2)}</Td>
              </Tr>
            </Tbody>
          </Table>
          <Elements
            options={{
              clientSecret: subscriptionResult.stripeClientSecret,
              appearance,
            }}
            stripe={stripePromise}
          >
            <SubscriptionInfo
              stripeClientSecret={subscriptionResult.stripeClientSecret}
            />
          </Elements>
        </>
      )}
      {loading && <CenteredSpinner />}
    </Box>
  )
}

const SubscriptionPaymentSuccess = (): JSX.Element => (
  <div className="centered padded">
    <div className="icon-round">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.6666 8L12.7777 22.8882L5.33325 15.4444"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <h1>Payment success!</h1>
    <p className="p-mid">
      Your payment has been processed successfully. Close this window to
      continue to your channel.
    </p>
  </div>
)

const SubscriptionFreeSuccess = (): JSX.Element => {
  const { channel } = useChannel()
  const query = useQuery()
  const subscriptionOptionIdQueryParam = query.get('subscriptionId')

  const [isLoading, setIsLoading] = useState(true)
  const [isSubscribed, setIsSubscribed] = useState(false)

  useEffect(() => {
    const subscribe = async () => {
      const result = await webGatewayApi.post<CreateSubscriptionResponseData>(
        '/payments/subscription/create',
        {
          data: {
            channelId: channel.id,
            subscriptionOptionId: subscriptionOptionIdQueryParam,
          },
        }
      )

      if (result.errors.length) {
        setIsSubscribed(false)
      } else {
        setIsSubscribed(true)
      }
      setIsLoading(false)
    }
    subscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      maxWidth={450}
      margin="0 auto"
      p={4}
      borderTopWidth={1}
      borderTopColor={'boxBorder.default'}
    >
      {isLoading && <CenteredSpinner />}
      {!isLoading && isSubscribed && (
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Subscription Success
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            You are now subscribed to this channel. Close this window to
            continue to your channel.
          </AlertDescription>
        </Alert>
      )}
      {!isLoading && !isSubscribed && (
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Subscription Error
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            An error ocurred while subscribing to this channel. Please close
            this window and try again.
          </AlertDescription>
        </Alert>
      )}
    </Box>
  )
}

export {
  SubscriptionPaymentCheckout,
  SubscriptionPaymentSuccess,
  SubscriptionFreeSuccess,
}
